import { useCallback, useEffect, useState } from 'react'
import * as S from './styles'
import { Modal } from 'antd'
import { useGames } from 'contexts/game'

type CorrectionType = {
  paragraphs?: any
}

function Correction({ paragraphs: datas }: CorrectionType) {
  const { saveGrade = () => true } = useGames()
  const [grade, setGrade] = useState(0)
  const [modal, setModal] = useState(false)
  const [paragraphs, setParagraphs] = useState<any>([])
  const [update, setUpdate] = useState(false)

  const handleShowCorrection = useCallback(() => {
    let g = 0

    datas.forEach((p: any) => {
      g += p.grade

      p.open = false
    })

    setModal(true)
    setGrade(g)

    setParagraphs(datas)
    setUpdate(!update)

    saveGrade(g)
  }, [datas, saveGrade, update])

  useEffect(() => {
    handleShowCorrection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = useCallback(
    (index) => {
      datas.forEach((p: any, i: number) => {
        if (index === i) {
          console.log(p)
          p.open = !p.open
        } else {
          p.open = false
        }
      })

      console.log({ datas })
      setParagraphs(datas)
      setUpdate(!update)
    },
    [datas, update]
  )

  return (
    <>
      <Modal
        visible={modal}
        onOk={() => setModal(false)}
        onCancel={() => setModal(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h2>
            {grade < 400
              ? 'Não desanime, vamos estudar mais e melhorar essa nota!'
              : grade < 600
              ? 'Com muito treino podemos melhorar!'
              : 'Muito bom! Você está no caminho!'}
          </h2>
          <p>Sua nota na redação foi: </p>
          <h3>{grade}</h3>

          <p>
            Clique nos parágrafos e veja
            <br />o que o professor escreveu para você.
          </p>
        </div>
      </Modal>
      <S.Wrapper>
        {paragraphs.map((paragraph: any, index: number) => (
          <S.Part key={paragraph.id}>
            <S.Paragraph onClick={() => handleOpen(index)}>
              {paragraph.paragraph}
            </S.Paragraph>
            {paragraph.open && (
              <S.Details>
                <S.DetailsInfo>
                  <h3>
                    Correção{' '}
                    {index === 0
                      ? 'da Introdução'
                      : index === 1
                      ? 'do Desenvolvimento 1'
                      : index === 2
                      ? 'do Desenvolvimento 2'
                      : 'da Conclusão'}
                  </h3>
                  <S.Patch>{paragraph.patch_description}</S.Patch>
                </S.DetailsInfo>
                {paragraph.patch_competence_1 !== undefined && (
                  <S.DetailsInfo>
                    <h3>Competência 1</h3>
                    <S.Patch>{paragraph.patch_competence_1}</S.Patch>
                  </S.DetailsInfo>
                )}
                {paragraph.patch_competence_2 !== undefined && (
                  <S.DetailsInfo>
                    <h3>Competência 2</h3>
                    <S.Patch>{paragraph.patch_competence_2}</S.Patch>
                  </S.DetailsInfo>
                )}
                {paragraph.patch_competence_3 !== undefined && (
                  <S.DetailsInfo>
                    <h3>Competência 3</h3>
                    <S.Patch>{paragraph.patch_competence_3}</S.Patch>
                  </S.DetailsInfo>
                )}
                {paragraph.patch_competence_4 !== undefined && (
                  <S.DetailsInfo>
                    <h3>Competência 4</h3>
                    <S.Patch>{paragraph.patch_competence_4}</S.Patch>
                  </S.DetailsInfo>
                )}
                {paragraph.patch_competence_5 !== undefined && (
                  <S.DetailsInfo>
                    <h3>Competência 5</h3>
                    <S.Patch>{paragraph.patch_competence_5}</S.Patch>
                  </S.DetailsInfo>
                )}
              </S.Details>
            )}
          </S.Part>
        ))}
      </S.Wrapper>
    </>
  )
}

export default Correction
