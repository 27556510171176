import styled from 'styled-components'
import { Table, Modal as AntdModal, Popconfirm } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

export const Container = styled.div`
  padding: 20px;
`

export const Title = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.bold};
  font-size: 23px;
  font-weight: 600;
`

export const Description = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.clear};
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 991px) {
    font-size: 16px;
  }
`

export const Buttons = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  gap: 0 5px;
`

export const Anchor = styled.a.attrs({
  target: '_blank'
})``

export const List = styled(Table)`
  margin-top: 20px;
`
export const { Column } = Table
export const Modal = styled(AntdModal)``
export const CheckIcon = styled(CheckOutlined)`
  font-size: 20px;
  color: green;
`
export const Confirm = styled(Popconfirm)``

export const WrapperModal = styled.div`
  width: 100%;
`
