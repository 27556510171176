import * as S from './styles'
import { Spin } from 'antd'

function LoadingWrapper() {
  return (
    <S.Container>
      <Spin size="large" />
    </S.Container>
  )
}

export default LoadingWrapper
