import * as S from './styles'

function WelcomeVideo() {
  return (
    <S.Container>
      <S.Title>Veja como usar a plataforma</S.Title>
      <S.Iframe
        src={`https://www.youtube.com/embed/v9O9IxEBlHI`}
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></S.Iframe>
    </S.Container>
  )
}

export default WelcomeVideo
