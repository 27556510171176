import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Input, Button, Modal, message, notification } from 'antd'
import Upload from 'components/atoms/Upload'
import NumberFormat from 'react-number-format'
import * as S from './styles'
import { useUsers } from 'contexts/user'
import LoadingWrapper from 'components/molecules/LoadingWrapper'

function Configuration() {
  const [modalVisible, setModalVisible] = useState(false)
  const [lastPassword, setLastPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const { handleSubmit, control, setValue } = useForm()
  const {
    getInitialProfile = () => true,
    loading,
    formLoading,
    user,
    handleEditProfile = () => true,
    handleEditPassword = () => true,
    handleEditAvatar = () => true,
    avatarLoading,
    passLoading
  } = useUsers()

  useEffect(() => {
    getInitialProfile()
  }, [getInitialProfile])

  useEffect(() => {
    if (user) {
      setValue('name', user.name)
      setValue('email', user.email)
      setValue('phone', user.phone)
      setValue('city', user.city)
      setValue('state', user.state)
    }
  }, [setValue, user])

  const onSubmit = (data: any) => {
    const { email, name } = data

    console.log('chamaaaaa')

    if (!email || !name) {
      notification.error({
        message: 'Atenção',
        description: 'Há campos obrigatórios vazios'
      })
      return
    }

    handleEditProfile(data)
  }

  const changePassword = () => {
    if (!newPassword || !newPassword2 || !lastPassword) {
      message.error('Preencha os campos para altera a senha')
      return
    }

    if (newPassword !== newPassword2) {
      message.error('Os campos de nova senha não são iguais.')
      return
    }

    handleEditPassword({ password: newPassword, last_password: lastPassword })
    setModalVisible(false)
    setNewPassword('')
    setNewPassword2('')
    setLastPassword('')
  }

  if (loading) {
    return <LoadingWrapper />
  }

  return (
    <S.Container>
      <S.Content>
        <Upload
          initialImageAvatar={user?.avatar}
          onChange={(file) =>
            handleEditAvatar({ file, hasPhoto: !!user?.avatar })
          }
          loading={avatarLoading}
          type="avatar"
        />
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Field>
            <S.Label>Nome completo</S.Label>
            <Controller
              as={<Input placeholder="Digite seu nome completo" />}
              name="name"
              control={control}
              defaultValue={user?.name}
            />
          </S.Field>
          <S.Field>
            <S.Label>Email</S.Label>
            <Controller
              as={<Input placeholder="Digite seu email" />}
              name="email"
              control={control}
              defaultValue={user?.email}
            />
          </S.Field>

          <S.Field>
            <S.Label>Telefone</S.Label>
            <Controller
              as={
                <NumberFormat
                  customInput={Input}
                  placeholder="(XX) XXXXX-XXXX"
                  format="(##) #####-####"
                />
              }
              name="phone"
              control={control}
              defaultValue={user?.phone}
            />
          </S.Field>

          <S.DoubleField>
            <S.Field>
              <S.Label>Cidade</S.Label>
              <Controller
                as={<Input placeholder="Digite sua cidade" />}
                name="city"
                control={control}
                defaultValue={user?.city}
              />
            </S.Field>

            <S.Field className="min-side">
              <S.Label>Estado</S.Label>
              <Controller
                as={<Input placeholder="Estado" />}
                name="state"
                control={control}
                defaultValue={user?.state}
              />
            </S.Field>
          </S.DoubleField>
          <S.Footer>
            <Button loading={formLoading} htmlType="submit" type="primary">
              Salvar
            </Button>
          </S.Footer>
        </S.Form>
        <S.ChangePasswordWrapper>
          <S.Text>Deseja alterar sua senha?</S.Text>
          <Button
            type="default"
            loading={passLoading}
            onClick={() => setModalVisible(true)}
          >
            Alterar
          </Button>
        </S.ChangePasswordWrapper>
        <Modal
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onOk={changePassword}
          title="Alterar senha"
          okText="Alterar"
        >
          <S.Field>
            <S.Label>Última senha</S.Label>
            <Input.Password
              type="password"
              onChange={(e) => setLastPassword(e.target.value)}
              value={lastPassword}
            />
          </S.Field>

          <S.Field>
            <S.Label>Nova senha</S.Label>
            <Input.Password
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
            />
          </S.Field>

          <S.Field>
            <S.Label>Repita a senha</S.Label>
            <Input.Password
              type="password"
              value={newPassword2}
              onChange={(e) => setNewPassword2(e.target.value)}
            />
          </S.Field>
        </Modal>
      </S.Content>
    </S.Container>
  )
}

export default Configuration
