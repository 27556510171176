import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Logo = styled.img`
  max-width: 75px;
  object-fit: contain;
`

export const Central = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  /* background: green; */
  justify-content: center;
  align-items: center;
`

export const HoraRedacao = styled.div`
  font-size: 25px;
  width: fit-content;
  color: white;
  font-weight: bold;
`

export const Description = styled.p`
  font-size: 17px;
  width: fit-content;
  color: white;
  text-align: center;
  font-weight: 500;
`

export const FormWrapper = styled.form`
  width: 100%;
  max-width: 600px;
  min-height: 450px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`
export const Field = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 13px;

  &.min-side {
    width: 30%;

    @media (max-width: 991px) {
      width: 100%;
    }
  }
`

export const Label = styled.div`
  width: 100%;
  font-size: 14px;
  color: white;

  &.space-between {
    display: flex;
    margin-bottom: -3px;
    justify-content: space-between;
    align-items: center;

    .ant-btn-link {
      padding: 0;
    }
  }
`

export const FormFooter = styled.footer`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const CreateAnAccount = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px 0;
`

export const QuestionCreate = styled.div`
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 19px;
`
