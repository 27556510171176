import * as S from './styles'
import { useForm, Controller } from 'react-hook-form'
import { Input, Button } from 'antd'

import { Link } from 'react-router-dom'
import { useAuth } from 'contexts/login'

function Login() {
  const { signIn = () => true, loading } = useAuth()
  const { handleSubmit, control } = useForm()

  return (
    <S.Wrapper>
      <S.Central>
        <S.FormWrapper onSubmit={handleSubmit(signIn)}>
          <S.Logo src="/logo-l.png" alt="logo" />
          <S.HoraRedacao>Hora da Redação</S.HoraRedacao>
          <S.Description>Digite seu email e senha para se logar</S.Description>
          <S.Field>
            <S.Label>Email</S.Label>
            <Controller
              as={
                <Input required type="email" placeholder="Digite seu email" />
              }
              control={control}
              name="email"
            />
          </S.Field>
          <S.Field>
            <S.Label className="space-between">
              Senha
              <Link to="/esqueci-minha-senha">
                <Button type="link" htmlType="button">
                  Esqueceu sua senha?
                </Button>
              </Link>
            </S.Label>
            <Controller
              as={<Input.Password required placeholder="Digite sua senha" />}
              control={control}
              name="password"
            />
          </S.Field>
          <S.FormFooter>
            <Button
              loading={loading}
              disabled={loading}
              htmlType="submit"
              type="primary"
            >
              Entrar
            </Button>
          </S.FormFooter>
        </S.FormWrapper>
      </S.Central>
      <S.CreateAnAccount>
        <S.QuestionCreate>Ainda não tem uma conta?</S.QuestionCreate>
        <Link to="/cadastre-se">
          <Button>Cadastre-se</Button>
        </Link>
      </S.CreateAnAccount>
    </S.Wrapper>
  )
}

export default Login
