import { Spin } from 'antd'
import { useEbooks } from 'contexts/ebooks'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import * as S from './styles'

const PdfViewer = () => {
  const { loadEbook = () => true, ebook, pdfloading } = useEbooks()
  const { id } = useParams<any>()

  useEffect(() => {
    loadEbook(id)
  }, [id, loadEbook])

  if (pdfloading) {
    return (
      <S.LoadingWrapper>
        <Spin size="large" />
      </S.LoadingWrapper>
    )
  }

  if (ebook.name === undefined) {
    return (
      <S.LoadingWrapper>
        <span>Não encontrado</span>
      </S.LoadingWrapper>
    )
  }

  return (
    <S.Container>
      <S.Title>{ebook.name}</S.Title>
      <S.Images>
        <S.Protector />
        {ebook.pdf_images.map((img: any) => (
          <S.Image loading="lazy" key={img} src={img} />
        ))}
      </S.Images>
    </S.Container>
  )
}

export default PdfViewer
