import * as S from './styles'
import { Link } from 'react-router-dom'
import { useProgress } from 'contexts/progress'
import { useUsers } from 'contexts/user'
import { useEffect } from 'react'
import MenuLoader from '../MenuLoader'
import ProfileSidebar from 'components/atoms/ProfileSidebar'

function Sidebar() {
  const { sidebarIsOpen, handleChangeSideBar } = useProgress()
  const { loadGold, goldLoading, isGold } = useUsers()

  useEffect(() => {
    loadGold && loadGold()
  }, [loadGold])

  return (
    <S.Container isOpen={sidebarIsOpen}>
      <S.Image>
        <S.Logo />
      </S.Image>
      <ProfileSidebar />
      {goldLoading ? (
        <MenuLoader />
      ) : (
        <S.Items>
          <S.ListItems>
            <Link onClick={() => handleChangeSideBar()} to="/home">
              <S.Item>
                <S.HomeIcon />
                <S.Span>Home</S.Span>
              </S.Item>
            </Link>
            <Link onClick={() => handleChangeSideBar()} to="/jogo">
              <S.Item>
                <S.ThemeIcon />
                <S.Span>O Jogo</S.Span>
              </S.Item>
            </Link>
            <Link onClick={() => handleChangeSideBar()} to="/e/temas-semanais">
              <S.Item>
                <S.StudentIcon />
                <S.Span>Temas Semanais</S.Span>
              </S.Item>
            </Link>
            {isGold && (
              <>
                <Link
                  onClick={() => handleChangeSideBar()}
                  to="/v/esquemas-em-video"
                >
                  <S.Item>
                    <S.VideoIcon />
                    <S.Span>Esquema em Vídeo</S.Span>
                  </S.Item>
                </Link>
                <Link
                  onClick={() => handleChangeSideBar()}
                  to="/v/aulas-enem-2021"
                >
                  <S.Item>
                    <S.VideoIcon />
                    <S.Span>Aulas ENEM 2021</S.Span>
                  </S.Item>
                </Link>
                <Link onClick={() => handleChangeSideBar()} to="/v/aulas-enem">
                  <S.Item>
                    <S.VideoIcon />
                    <S.Span>Aulas ENEM</S.Span>
                  </S.Item>
                </Link>
                <Link onClick={() => handleChangeSideBar()} to="/v/aulas-uerj">
                  <S.Item>
                    <S.VideoIcon />
                    <S.Span>Aulas UERJ</S.Span>
                  </S.Item>
                </Link>
                <Link
                  onClick={() => handleChangeSideBar()}
                  to="/v/aulas-concurso"
                >
                  <S.Item>
                    <S.VideoIcon />
                    <S.Span>Aulas Concurso</S.Span>
                  </S.Item>
                </Link>
                <Link
                  onClick={() => handleChangeSideBar()}
                  to="/v/aulas-escolas-militares"
                >
                  <S.Item>
                    <S.VideoIcon />
                    <S.Span>Aulas Escolas Militares</S.Span>
                  </S.Item>
                </Link>
              </>
            )}
            <Link onClick={() => handleChangeSideBar()} to="/v/quadros">
              <S.Item>
                <S.VideoIcon />
                <S.Span>Quadro</S.Span>
              </S.Item>
            </Link>
            <Link
              onClick={() => handleChangeSideBar()}
              to="/e/ppt-interdisciplinar"
            >
              <S.Item>
                <S.BookIcon />
                <S.Span>PPT Interdisciplinar</S.Span>
              </S.Item>
            </Link>
            <Link onClick={() => handleChangeSideBar()} to="/podcasts">
              <S.Item>
                <S.PodcastIcon />
                <S.Span>Podcast</S.Span>
              </S.Item>
            </Link>
            <Link onClick={() => handleChangeSideBar()} to="/e/ebooks">
              <S.Item>
                <S.CorrectionsIcons />
                <S.Span>E-book</S.Span>
              </S.Item>
            </Link>
            <Link
              onClick={() => handleChangeSideBar()}
              to="/e/redacoes-exemplares"
            >
              <S.Item>
                <S.SuggestionsIcon />
                <S.Span>Redações Exemplares</S.Span>
              </S.Item>
            </Link>
            <Link onClick={() => handleChangeSideBar()} to="/maratona">
              <S.Item>
                <S.MarathonIcon />
                <S.Span>Maratona</S.Span>
              </S.Item>
            </Link>
            {/* {isGold && (
              <Link
                onClick={() => handleChangeSideBar()}
                to="/faca-sua-redacao"
              >
                <S.Item>
                  <S.MakeYourComposition />
                  <S.Span>Faça sua Redação</S.Span>
                </S.Item>
              </Link>
            )} */}
          </S.ListItems>
        </S.Items>
      )}
    </S.Container>
  )
}

export default Sidebar
