import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 300px;
  padding: 10px 10px 10px 0;
  border-radius: 6px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.normal} !important;

  .recharts-responsive-container {
    padding: 0 !important;
    margin: 0 !important;
  }
`
