import styled from 'styled-components'
import { Button as AntdButton } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { AiOutlineArrowLeft } from 'styles/icons'

export const Container = styled.div`
  width: 100%;
  height: 48px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  position: relative;

  z-index: 700;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  justify-content: space-between;
`

export const Left = styled.div`
  display: flex;
  gap: 0 10px;
  margin-top: 6px;
`

export const Right = styled.div`
  gap: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const WrapperContent = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  display: none;

  @media (max-width: 991px) {
    z-index: 800;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  }
`

export const Button = styled(AntdButton)``
export const UserIcons = styled(UserOutlined)``

export const Arrow = styled(AiOutlineArrowLeft)`
  width: 24px;
  height: 24px;
  color: black;
  cursor: pointer;
`

export const Mobile = styled.div`
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
`
