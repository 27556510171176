import * as S from './styles'

import { useParams } from 'react-router-dom'
import { useMarathon } from 'contexts/marathon'
import { useEffect } from 'react'

function AppPDF() {
  const { id, user } = useParams<any>()
  const { loadPageAppToken = () => true, marathon, loading } = useMarathon()

  useEffect(() => {
    loadPageAppToken(id, user)
  }, [id, loadPageAppToken, user])

  if (loading) {
    return (
      <S.LoadingWrapper>
        <S.Loading>
          <S.Separator />
          <S.Intern />
        </S.Loading>
      </S.LoadingWrapper>
    )
  }

  return (
    <S.Wrapper>
      {marathon.pdf_images.map((img: string) => (
        <S.Images src={img} key={img} />
      ))}
    </S.Wrapper>
  )
}

export default AppPDF
