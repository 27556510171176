import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px;
`
export const LoadingWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  margin-top: 20px;
`

export const Title = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.bold};
  font-size: 23px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PTitle = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.bold};
  font-size: 17px;
  font-weight: 500;
`
export const Paragraph = styled.p`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.normal};
  font-size: 15px;
  font-weight: 400;
  transition: 0.6s;
  padding-bottom: 15px;
`
export const Part = styled.div`
  cursor: pointer;
  transition: 0.6s;
  padding-top: 15px;

  &:hover {
    p {
      color: #1880ff;
      font-weight: 400;
    }
  }

  & + div {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`

export const EndWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 15px;
  margin-top: 20px;

  @media (max-width: 400px) {
    flex-direction: column;
    gap: 15px 0;
  }
`

export const Details = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px 0;
`

export const DetailsInfo = styled.div`
  width: 100%;
  display: flex;
  line-height: 15px;
  flex-direction: column;
  margin-bottom: 15px;

  h3 {
    font-weight: 600;
    font-size: 18px;
  }

  &:hover {
    color: inherit;
  }
`

export const Patch = styled.div`
  color: black;
`
