import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
`
export const Images = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const load = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

export const Loading = styled.div`
  width: 25px;
  height: 25px;
  background: #0e7ae8;
  border-radius: 50%;
  animation: ${load} linear infinite 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const Separator = styled.div`
  width: 7px;
  height: 7px;
  background-color: white;
  position: absolute;
  bottom: 0;
`

export const Intern = styled.div`
  width: 23px;
  height: 23px;
  background: white;
  border-radius: 50%;
`
