import { message } from 'antd'
import { createContext, useContext, useState, useCallback } from 'react'
import api from 'services/api'
import getIdFromLocalStorage from 'utils/getIdFromLocalStorage'

export type UserProps = {
  email?: string
  username?: string
  is_superuser?: BooleanConstructor
  children?: React.ReactNode
}

type UserContextType = {
  loading: boolean
  loadGold?: () => void
  actionActivity?: () => void
  changePassword?: (data: any) => void
  getInitialProfile?: () => void
  handleEditProfile?: (data: UserType) => void
  handleEditAvatar?: (data: any) => void
  handleEditPassword?: (data: any) => void
  goldLoading: boolean
  user?: UserType
  formLoading?: boolean
  passLoading?: boolean
  avatarLoading?: boolean
  isGold?: boolean
}

type UserType = {
  name: string
  email: string
  phone: string
  city: string
  state: string
  avatar?: string
  access_type: string
}

const userContextDefaultValue: UserContextType = {
  loading: false,
  goldLoading: true,
  formLoading: false,
  passLoading: false,
  avatarLoading: false,
  user: undefined
}

const UserContext = createContext<UserContextType>(userContextDefaultValue)

export const UserProvider = ({ children }: UserProps) => {
  const [isGold, setIsGold] = useState(false)
  const [user, setUser] = useState<any>()
  const [goldLoading, setGoldLoading] = useState(true)
  const [loading, setLoading] = useState(true)
  const [formLoading, setFormLoading] = useState(false)
  const [passLoading, setPassLoading] = useState(false)
  const [avatarLoading, setAvatarLoading] = useState(false)

  // const fn = useCallback(async() => {}, [])

  const getInitialProfile = useCallback(async () => {
    setLoading(true)
    const response = await api.get('/user/info')
    setLoading(false)

    if (response.status >= 400) return

    setUser(response.data)
  }, [])

  const handleEditProfile = useCallback(async (data: UserType) => {
    setFormLoading(true)

    const id = getIdFromLocalStorage()

    if (!id) return

    const response = await api.put(`/user/${id}`, data)

    if (response.status >= 400) return

    message.success('Conta editada com sucesso!')
    setFormLoading(false)
  }, [])

  const handleEditPassword = useCallback(async (data: any) => {
    const id = getIdFromLocalStorage()
    if (!id) return
    setPassLoading(true)
    const response = await api.patch('/user/password', data)
    setPassLoading(false)
    if (response.status >= 400) return
    message.success('Senha alterada com sucesso!')
  }, [])

  const handleEditAvatar = useCallback(async (data: any) => {
    const id = getIdFromLocalStorage()
    if (!id) return
    if (!data.file) return

    let response

    const dataFile = new FormData()
    dataFile.append('image', data.file)
    dataFile.append('id', id)

    setAvatarLoading(true)
    if (!data.hasPhoto) {
      response = await api.post('/user/avatar', dataFile)
    } else {
      response = await api.patch('/user/avatar', dataFile)
    }
    setAvatarLoading(false)

    if (response.status >= 400) return

    message.success('Foto atualizada com sucesso!')

    console.log(data)
  }, [])

  const loadGold = useCallback(async () => {
    setGoldLoading(true)
    const response = await api.get('/user/metadata')

    if (response.status >= 400) return

    setIsGold(response.data.is_gold)

    setGoldLoading(false)
  }, [])

  const actionActivity = useCallback(async () => {
    await api.get('/home/action')
  }, [])

  return (
    <UserContext.Provider
      value={{
        loadGold,
        goldLoading,
        isGold,
        user,
        getInitialProfile,
        handleEditPassword,
        handleEditProfile,
        handleEditAvatar,
        avatarLoading,
        passLoading,
        formLoading,
        actionActivity,
        loading
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUsers = () => useContext(UserContext)
