import styled from 'styled-components'
import { BsPlay, BsPause, GiBackwardTime } from 'styles/icons'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
`

export const WardIcon = styled(GiBackwardTime)`
  width: 20px;
  height: 20px;
  margin-top: 2px;
  color: #1890ff;

  &.forward-ic {
    transform: scaleX(-1);
  }
`

export const BackgroundProgressBar = styled.div`
  width: 100%;
  height: 5px;
  background: #001529;
  border-radius: 20px;
`

export const ProgressBar = styled.div<{ progress: number }>`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  background: #848484;
  border-radius: 10px;
`

export const Range = styled.input`
  width: 100%;
`

export const Play = styled(BsPlay)`
  width: 20px;
  height: 20px;
  color: white;
`

export const Pause = styled(BsPause)`
  width: 20px;
  height: 20px;
  color: white;
`

export const Times = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Time = styled.div`
  color: ${({ theme }) => theme.colors.text.normal};
  font-size: 16px;
  font-style: italic;
`
