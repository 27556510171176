import { notification } from 'antd'
import { createContext, useContext, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'
import { logout } from 'services/userAuth'

export type UserProps = {
  email?: string
  username?: string
  is_superuser?: BooleanConstructor
  children?: React.ReactNode
}

type UserFormType = {
  email: string
  password: string
  name?: string
  enter_code?: string
}

type UserContextType = {
  loading: boolean
  isAuthenticated: boolean
  signIn?: (data: UserFormType) => void
  signOut?: () => void
  register?: (data: any) => void
  forgotPassword?: (data: any) => void
  changePassword?: (data: any) => void
  user: UserType
}

type UserType = {
  name: string
  email: string
  id: string
}

const userContextDefaultValue: UserContextType = {
  loading: false,
  isAuthenticated: false,
  user: {
    name: '',
    email: '',
    id: ''
  }
}

const AuthContext = createContext<UserContextType>(userContextDefaultValue)

export const AuthProvider = ({ children }: UserProps) => {
  const history = useHistory()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<UserType>({
    name: '',
    email: '',
    id: ''
  })

  const signIn = useCallback(
    async (data: UserFormType) => {
      setLoading(true)
      const response = await api.post('/auth', data)
      setLoading(false)

      if (response.status >= 400) return

      const { name, token, email, id, access_type } = response.data

      const userData = {
        name,
        email,
        id,
        level: access_type
      }

      localStorage.setItem('HORADAREDACAO_AUTH', token)
      localStorage.setItem('HORADAREDACAO_USER', JSON.stringify(userData))

      setUser(userData)
      setIsAuthenticated(true)
      history.push('/home')
    },
    [history]
  )

  const register = useCallback(
    async (data: UserFormType) => {
      setLoading(true)
      const response = await api.post('/user', {
        ...data,
        is_superadmin: false
      })
      setLoading(false)

      if (response.status >= 400) return

      const { access_type } = response.data

      notification.success({
        message: 'Sucesso!',
        description: `Sua conta com acesso ${access_type.toLowerCase()} foi criada com sucesso!
        Agora você pode fazer o login.`
      })

      history.push('/login')
    },
    [history]
  )

  const forgotPassword = useCallback(async (data: any) => {
    setLoading(true)
    const response = await api.post('/auth/forgot-password', data)
    setLoading(false)

    if (response.status >= 400) return

    notification.success({
      message: 'Sucesso!',
      description: `Foi enviado um link para o seu email!
        Caso não apareça na sua caixa de entrada, veja também na sua caixa de spam.`,
      duration: 0
    })
  }, [])

  const changePassword = useCallback(
    async (data: any) => {
      setLoading(true)
      const response = await api.post('/auth/change-password', data)
      setLoading(false)

      if (response.status >= 400) return

      notification.success({
        message: 'Sucesso!',
        description: `Sua senha foi alterada com sucesso! Você pode se logar novamente.`
      })
      history.push('/login')
    },
    [history]
  )

  const signOut = useCallback(async () => {
    logout()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        signIn,
        signOut,
        forgotPassword,
        register,
        changePassword,
        user,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
