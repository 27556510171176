import styled from 'styled-components'
import { FaRunning } from 'styles/icons'
import { EditOutlined } from '@ant-design/icons'

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  @media (max-width: 991px) {
    padding: 10px;
  }
`

export const Section = styled.div`
  width: 100%;
  height: 415px;
  display: flex;
  gap: 0 20px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    height: fit-content;
    flex-direction: column;
    justify-content: center;
  }
`

export const Left = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 13px 0;

  @media (max-width: 991px) {
    width: 100%;
  }
`

export const Right = styled.div`
  width: 30%;

  @media (max-width: 991px) {
    width: 100%;
  }
`

export const Card = styled.div<{ bg?: string }>`
  width: 100%;
  height: 200px;
  border-radius: 7px;
  background-image: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 8;
  position: relative;

  &.c-pointer {
    cursor: pointer;
  }
`

export const VerticalCard = styled.div<{ bg?: string }>`
  width: 100%;
  height: 415px;
  border-radius: 7px;
  background-image: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 991px) {
    height: 200px;
    margin-top: 13px;
  }
`

export const WrapperCard = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.4);
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Title = styled.div`
  color: white;
  font-weight: 700;
  font-size: 34px;

  @media (max-width: 991px) {
    font-size: 25px;
  }
`

export const Description = styled.p`
  color: white;
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;

  @media (max-width: 991px) {
    font-size: 15px;
  }
`

export const SeeMore = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
  font-size: 15px;
  z-index: 10;
  font-weight: 600;
`

export const DashboardSection = styled.div`
  width: 100%;
  margin-top: 13px;
  display: flex;
  flex-direction: column;
`

export const DashboardTitle = styled.div`
  width: 100%;
  padding: 5px 5px 5px 2px;
  color: black;
  font-size: 20px;
  font-weight: 600;
`

export const Maker = styled.div`
  width: 100%;
  display: flex;
  gap: 0 13px;
  margin-top: 15px;

  a {
    width: 100%;
    height: 100%;
  }
`

export const MiniCard = styled.div<{ bg?: string }>`
  width: 50%;
  border-radius: 7px;
  height: 60px;
  background-image: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.not-gold {
    width: 100%;
  }

  @media (max-width: 647px) {
    height: 150px;
  }
`
export const MiniCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);

  @media (max-width: 647px) {
    flex-direction: column;
    justify-content: center;
    gap: 15px 0;
    text-align: center;
  }
`

export const MiniCardTitle = styled.div`
  color: white;
  font-size: 23px;
  font-weight: 600;

  @media (max-width: 370px) {
    font-size: 18px;
  }
`

export const MarathonIcon = styled(FaRunning)`
  width: 35px;
  height: 35px;
  color: white;
`
export const CompositionIcon = styled(EditOutlined)`
  font-size: 35px;
  color: white;
`
