import * as S from './styles'
import { Link } from 'react-router-dom'

function Error404() {
  return (
    <S.Container>
      <h1>Erro 404</h1>
      <span>Página não encontrada</span>
      <Link to="/home" type="primary">
        Voltar ao início
      </Link>
    </S.Container>
  )
}

export default Error404
