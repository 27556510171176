import styled, { css } from 'styled-components'
import {
  FiMonitor,
  BsJournalText,
  FiHeadphones,
  RiFileEditLine,
  MdShowChart
} from 'styles/icons'
//

export const Container = styled.div`
  width: 100%;
  padding: 20px;
`
export const Title = styled.div<{ noContent?: boolean }>`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.text.bold};
  margin-bottom: 20px;

  ${({ noContent }) =>
    noContent &&
    css`
      width: 100%;
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: pre-wrap;
      text-align: center;
    `}

  @media (max-width: 1052px) {
    font-size: 25px;
  }
  @media (max-width: 361px) {
    font-size: 19px;
  }
`

export const Section = styled.div`
  display: flex;
  gap: 0 14px;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 14px 0;
  }
`

export const Card = styled.div`
  padding: 15px 40px;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  background: white;
  box-shadow: ${({ theme }) => theme.boxShadow.card};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.no-pad {
    padding: 15px;
  }

  &.chart {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
  }

  @media (max-width: 422px) {
    padding: 15px;
  }
`

export const ChartTitle = styled.div`
  width: 100%;
  font-size: 23px;
  color: ${({ theme }) => theme.colors.text.normal};
  text-align: center;
`

export const BigCard = styled.div`
  width: 50%;
  border-radius: 7px;

  &.complete {
    width: 100%;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
`

export const MiniCard = styled.div`
  width: 50%;
  border-radius: 7px;
`

export const MiniCards = styled.div`
  display: flex;
  width: 50%;
  gap: 0 14px;

  @media (max-width: 991px) {
    width: 100%;
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px 0;
  align-items: center;
  justify-content: center;
`

export const ItemTitle = styled.span`
  font-size: 25px;
  color: ${({ theme }) => theme.colors.text.clear};
  text-align: center;

  &.min-text {
    font-size: 20px;
  }

  @media (max-width: 1112px) {
    &.min-text {
      font-size: 17px;
    }
  }

  @media (max-width: 1052px) {
    font-size: 20px;
  }

  @media (max-width: 340px) {
    font-size: 17px;
  }
`

export const Number = styled.span`
  color: #1890ff;
  font-size: 40px;

  @media (max-width: 1100px) {
    font-size: 30px;
  }

  @media (max-width: 340px) {
    font-size: 22px;
  }
`

const cssIcon = css`
  width: 80px;
  height: 80px;
  color: #1890ff;

  @media (max-width: 1227px) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 340px) {
    width: 40px;
    height: 40px;
  }
`

export const MonitorIcon = styled(FiMonitor)`
  ${cssIcon}
`
export const ReadIcon = styled(BsJournalText)`
  ${cssIcon}
`
export const PhonesIcon = styled(FiHeadphones)`
  ${cssIcon}
`
export const CompositionIcon = styled(RiFileEditLine)`
  ${cssIcon}
`
export const ChartIcon = styled(MdShowChart)`
  ${cssIcon}
`

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`
