import { createGlobalStyle } from 'styled-components'
import 'antd/dist/antd.css'

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Poppins', sans-serif;
  }

  .ql-toolbar.ql-snow {
    border: 1px solid #d9d9d9 !important;
    border-radius: 2px 2px 0 0;
  }
  .ql-container.ql-snow {
    border: none;
    border-right: 1px solid #d9d9d9 !important;
    border-left: 1px solid #d9d9d9 !important;
    border-bottom: 1px solid #d9d9d9 !important;
    border-radius: 0 0 2px 2px;
  }
`

export default GlobalStyles
