import * as S from './styles'
import { Link } from 'react-router-dom'
import { Spin, Input, Pagination } from 'antd'
import { usePodcasts } from 'contexts/podcasts'
import { useUsers } from 'contexts/user'
import { useEffect, useState } from 'react'
import EmptyData from 'components/atoms/EmptyData'

const { Search } = Input

function Podcards() {
  const { goldLoading, isGold } = useUsers()
  const { list, loadList = () => true, loading, pageNo } = usePodcasts()
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!goldLoading) {
      loadList(Boolean(isGold))
    }
  }, [goldLoading, isGold, loadList])

  const onSearch = (data: string) => {
    setSearch(data)

    loadList(Boolean(isGold), data)
  }

  return (
    <S.Container>
      <S.Title>Podcasts</S.Title>
      <S.Description>
        Pilulas semanais sobre os mais variados assuntos
      </S.Description>
      <S.SearchArea>
        <S.Input>
          <Search
            placeholder="Pesquise aqui"
            allowClear
            enterButton
            onSearch={onSearch}
          />
        </S.Input>
      </S.SearchArea>
      {loading ? (
        <S.LoadingWrapper>
          <Spin size="large" />
        </S.LoadingWrapper>
      ) : list.podcasts && list.podcasts.length === 0 ? (
        <EmptyData visible label="Sem dados" />
      ) : (
        <>
          <S.Files>
            {list.podcasts.map((podcasts: any) => (
              <Link key={podcasts.id} to={`/podcasts/${podcasts.id}`}>
                <S.File title={podcasts.name}>
                  <S.Image src={podcasts.image} />
                  <S.FileTitle>{podcasts.name}</S.FileTitle>
                </S.File>
              </Link>
            ))}
          </S.Files>
          <S.Footer>
            <Pagination
              size="small"
              total={list.total}
              defaultPageSize={12}
              defaultCurrent={pageNo}
              current={pageNo}
              onChange={(page: number) =>
                loadList(Boolean(isGold), search, page)
              }
              hideOnSinglePage
            />
          </S.Footer>
        </>
      )}
    </S.Container>
  )
}

export default Podcards
