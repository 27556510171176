import { createContext, useContext, useState, useCallback } from 'react'
import { randomBetween } from 'utils/randomKey'

export type ProgressProps = {
  children?: React.ReactNode
}

type UserContextType = {
  changeProgress: (min: number, max?: number) => void
  handleChangeSideBar: () => void
  progress: number
  sidebarIsOpen: boolean
}

const progressContextDefaultValue: UserContextType = {
  progress: 0,
  sidebarIsOpen: false,
  changeProgress: () => true,
  handleChangeSideBar: () => true
}

const ProgressContext = createContext<UserContextType>(
  progressContextDefaultValue
)

export const ProgressProvider = ({ children }: ProgressProps) => {
  const [progress, setProgress] = useState(0)
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false)

  const changeProgress = useCallback((min: number, max?: number) => {
    let value: number = min

    if (max) {
      value = randomBetween(min, max)
    }

    setProgress(value)

    if (value === 100) {
      setTimeout(() => {
        setProgress(0)
      }, 2000)
    }
  }, [])

  const handleChangeSideBar = useCallback(() => {
    setSidebarIsOpen(!sidebarIsOpen)
  }, [sidebarIsOpen])

  return (
    <ProgressContext.Provider
      value={{
        progress,
        changeProgress,
        sidebarIsOpen,
        handleChangeSideBar
      }}
    >
      {children}
    </ProgressContext.Provider>
  )
}

export const useProgress = () => useContext(ProgressContext)
