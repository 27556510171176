/* eslint-disable react/display-name */
import * as S from './styles'
import { Link } from 'react-router-dom'
import DashboardCarousel, {
  Carousel
} from 'components/molecules/DashboardCarousel'
import { useDashboard } from 'contexts/dashboard'
import { useEffect } from 'react'
import Skeleton from 'components/atoms/Skeleton'
import { useUsers } from 'contexts/user'

function Home() {
  const {
    data1,
    data2,
    data3,
    loadingL1,
    loadingL2,
    loadingL3,
    loader = () => true
  } = useDashboard()
  const { goldLoading } = useUsers()

  useEffect(() => {
    loader()
  }, [loader])

  return (
    <S.Container>
      <S.Section>
        <S.Left>
          {loadingL1 ? (
            <>
              <Skeleton height={200} />
              <Skeleton height={200} />
            </>
          ) : (
            <>
              <S.Card className="c-pointer" bg="/assets/learn.jpeg">
                <Link to="/veja-como-usar-a-plataforma">
                  <S.WrapperCard>
                    <S.Title>Veja como usar a plataforma</S.Title>
                  </S.WrapperCard>
                </Link>
              </S.Card>
              <S.Card bg="/assets/semanais.jpeg">
                <Link to="/e/temas-semanais">
                  <S.SeeMore>Veja mais</S.SeeMore>
                </Link>
                <Link
                  to={
                    data1 && data1.ebook
                      ? `/e/temas-semanais/${data1.ebook.id}`
                      : '/e/temas-semanais'
                  }
                >
                  <S.WrapperCard>
                    <S.Title>Tema da semana</S.Title>
                  </S.WrapperCard>
                </Link>
              </S.Card>
            </>
          )}
        </S.Left>
        <S.Right>
          {loadingL1 ? (
            <Skeleton height={415} />
          ) : (
            <Link to="/jogo">
              <S.VerticalCard bg="/assets/jogo.jpeg">
                <S.WrapperCard>
                  <S.Title>Jogo da Redação</S.Title>
                  <S.Description>
                    Você é bom de redação? <br /> Teste seu conhecimento agora
                  </S.Description>
                </S.WrapperCard>
              </S.VerticalCard>
            </Link>
          )}
        </S.Right>
      </S.Section>
      <S.Maker>
        {loadingL1 || goldLoading ? (
          <>
            <Skeleton height={60} />
            {/* <Skeleton height={60} /> */}
          </>
        ) : (
          <>
            <S.MiniCard
              // className={!isGold ? 'not-gold' : ''}
              className={'not-gold'}
              bg="/assets/marathon.jpg"
            >
              <Link to="/maratona">
                <S.MiniCardWrapper>
                  <S.MarathonIcon />
                  <S.MiniCardTitle>Maratona ENEM</S.MiniCardTitle>
                </S.MiniCardWrapper>
              </Link>
            </S.MiniCard>
            {/* {isGold && (
              <S.MiniCard bg="/assets/redacao.jpg">
                <Link to="/faca-sua-redacao">
                  <S.MiniCardWrapper>
                    <S.CompositionIcon />
                    <S.MiniCardTitle>Faça sua redação</S.MiniCardTitle>
                  </S.MiniCardWrapper>
                </Link>
              </S.MiniCard>
            )} */}
          </>
        )}
      </S.Maker>
      <S.DashboardSection>
        {data1 && data1.boards.length > 0 && (
          <S.DashboardTitle>Quadros</S.DashboardTitle>
        )}

        {loadingL1 ? (
          <DashboardCarousel>
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
          </DashboardCarousel>
        ) : (
          <>
            {data1 && data1.boards.length > 0 && (
              <DashboardCarousel>
                {data1.boards.map((board: any) => (
                  <Carousel.BgCard key={board.id}>
                    <Link to={`/v/quadros/${board.id}`}>
                      <Carousel.Card bg={board.video_image}>
                        <Carousel.WrapperCard>
                          <Carousel.Title>{board.name}</Carousel.Title>
                        </Carousel.WrapperCard>
                      </Carousel.Card>
                    </Link>
                  </Carousel.BgCard>
                ))}

                <Link to="/v/quadros">
                  <Carousel.Card>
                    <Carousel.SeeMore>Veja mais</Carousel.SeeMore>
                  </Carousel.Card>
                </Link>
              </DashboardCarousel>
            )}
          </>
        )}
      </S.DashboardSection>
      <S.DashboardSection>
        {data2 && data2.ppts.length > 0 && (
          <S.DashboardTitle>PPT Interdisciplinar</S.DashboardTitle>
        )}

        {loadingL2 ? (
          <DashboardCarousel>
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
          </DashboardCarousel>
        ) : (
          <>
            {data2 && data2.ppts.length > 0 && (
              <DashboardCarousel>
                {data2.ppts.map((ppt: any) => (
                  <Carousel.BgCard key={ppt.id}>
                    <Link to={`/e/ppt-interdisciplinar/${ppt.id}`}>
                      <Carousel.Card bg={ppt.image}>
                        <Carousel.WrapperCard>
                          <Carousel.Title>{ppt.name}</Carousel.Title>
                        </Carousel.WrapperCard>
                      </Carousel.Card>
                    </Link>
                  </Carousel.BgCard>
                ))}

                <Link to="/e/ppt-interdisciplinar">
                  <Carousel.Card>
                    <Carousel.SeeMore>Veja mais</Carousel.SeeMore>
                  </Carousel.Card>
                </Link>
              </DashboardCarousel>
            )}
          </>
        )}
      </S.DashboardSection>
      <S.DashboardSection>
        {data2 && data2.podcasts.length > 0 && (
          <S.DashboardTitle>Podcasts</S.DashboardTitle>
        )}

        {loadingL2 ? (
          <DashboardCarousel>
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
          </DashboardCarousel>
        ) : (
          <>
            {data2 && data2.podcasts.length > 0 && (
              <DashboardCarousel>
                {data2.podcasts.map((podcast: any) => (
                  <Carousel.BgCard key={podcast.id}>
                    <Link to={`/podcasts/${podcast.id}`}>
                      <Carousel.Card bg={podcast.image}>
                        <Carousel.WrapperCard>
                          <Carousel.Title>{podcast.name}</Carousel.Title>
                        </Carousel.WrapperCard>
                      </Carousel.Card>
                    </Link>
                  </Carousel.BgCard>
                ))}

                <Link to="/podcasts">
                  <Carousel.Card>
                    <Carousel.SeeMore>Veja mais</Carousel.SeeMore>
                  </Carousel.Card>
                </Link>
              </DashboardCarousel>
            )}
          </>
        )}
      </S.DashboardSection>
      <S.DashboardSection>
        {data3 && data3.ebooks.length > 0 && (
          <S.DashboardTitle>E-book</S.DashboardTitle>
        )}

        {loadingL3 ? (
          <DashboardCarousel>
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
          </DashboardCarousel>
        ) : (
          <>
            {data3 && data3.ebooks.length > 0 && (
              <DashboardCarousel>
                {data3.ebooks.map((ebook: any) => (
                  <Carousel.BgCard key={ebook.id}>
                    <Link to={`/e/ebooks/${ebook.id}`}>
                      <Carousel.Card bg={ebook.image}>
                        <Carousel.WrapperCard>
                          <Carousel.Title>{ebook.name}</Carousel.Title>
                        </Carousel.WrapperCard>
                      </Carousel.Card>
                    </Link>
                  </Carousel.BgCard>
                ))}
                <Link to="/e/ebooks">
                  <Carousel.Card>
                    <Carousel.SeeMore>Veja mais</Carousel.SeeMore>
                  </Carousel.Card>
                </Link>
              </DashboardCarousel>
            )}
          </>
        )}
      </S.DashboardSection>
      <S.DashboardSection>
        {data3 && data3.compositions.length > 0 && (
          <S.DashboardTitle>Redações Exemplares</S.DashboardTitle>
        )}

        {loadingL3 ? (
          <DashboardCarousel>
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
            <Skeleton height={270} style={{ marginRight: 10 }} />
          </DashboardCarousel>
        ) : (
          <>
            {data3 && data3.compositions.length > 0 && (
              <DashboardCarousel>
                {data3.compositions.map((composition: any) => (
                  <Carousel.BgCard key={composition.id}>
                    <Link to={`/e/redacoes-exemplares/${composition.id}`}>
                      <Carousel.Card bg={composition.image}>
                        <Carousel.WrapperCard>
                          <Carousel.Title>{composition.name}</Carousel.Title>
                        </Carousel.WrapperCard>
                      </Carousel.Card>
                    </Link>
                  </Carousel.BgCard>
                ))}

                <Link to="/e/redacoes-exemplares">
                  <Carousel.Card>
                    <Carousel.SeeMore>Veja mais</Carousel.SeeMore>
                  </Carousel.Card>
                </Link>
              </DashboardCarousel>
            )}
          </>
        )}
      </S.DashboardSection>
    </S.Container>
  )
}

export default Home
