// travel - 2068 - 10:56 in 29 apr 2021

export default {
  type: 'prices',
  title: 'Conteúdos vistos',
  label: '',
  areas: [
    {
      name: 'Conteúdos vistos',
      lineColor: '#1890FF',
      dotColor: '#FFFFFF',
      serie: 'content'
    }
  ],
  series: [
    {
      name: '02/10',
      content: 1
    },
    {
      name: '03/10',
      content: 2
    },
    {
      name: '04/10',
      content: 1
    },
    {
      name: '05/10',
      content: 4
    },
    {
      name: '06/10',
      content: 5
    },
    {
      name: '07/10',
      content: 1
    },
    {
      name: '08/10',
      content: 0
    }
  ]
}
