import axios from 'axios'
import { createContext, useContext, useState, useCallback } from 'react'
import api, { baseURL } from 'services/api'
import { useUsers } from './user'

export type EbookProps = {
  children?: React.ReactNode
}

type EbookTypes = {
  loadList?: (
    category: string,
    isGold: boolean,
    search?: string,
    page?: number
  ) => void
  list: any
  loading: boolean
  pdfloading: boolean
  ebook: any
  pageNo: number
  loadEbook?: (id: string) => void
  loadPageAppToken?: (id: string, token: string) => void
}

const ebooksContextDefaultValue: EbookTypes = {
  list: undefined,
  ebook: undefined,
  loading: true,
  pdfloading: true,
  pageNo: 1
}

const EbooksContext = createContext<EbookTypes>(ebooksContextDefaultValue)

export const EbooksProvider = ({ children }: EbookProps) => {
  const [loading, setLoading] = useState(true)
  const { actionActivity = () => true } = useUsers()
  const [pdfloading, setPdfLoading] = useState(true)
  const [list, setList] = useState<any>(undefined)
  const [ebook, setEbook] = useState<any>(undefined)
  const [pageNo, setPage] = useState(1)

  const loadList = useCallback(
    async (category: string, isGold: boolean, search?: string, page = 1) => {
      setLoading(true)

      const filter: any = {
        category,
        gd: isGold,
        page,
        size: 12
      }

      if (search) {
        filter.search = search
      }

      const url = new URLSearchParams(filter).toString()

      setPage(page || 1)
      const response = await api.get(`/ebooks?${url}`)

      if (response.status >= 400) return

      setList(response.data)
      setLoading(false)
    },
    []
  )

  const loadEbook = useCallback(
    async (id: string) => {
      setPdfLoading(true)

      const response = await api.get(`/ebooks/${id}`)
      if (response.status >= 400) return

      setEbook(response.data)
      setPdfLoading(false)

      await api.post('/ebooks-read', {
        e_book_id: id
      })
      actionActivity()
    },
    [actionActivity]
  )

  const loadPageAppToken = useCallback(async (id: string, user: string) => {
    setLoading(true)

    const headers = {
      headers: {
        Authorization: `Bearer ${user}`
      }
    }

    const response = await axios.get(`${baseURL}/ebooks/${id}`, headers)
    if (response.status >= 400) return

    setEbook(response.data)
    setLoading(false)
  }, [])

  return (
    <EbooksContext.Provider
      value={{
        loadList,
        loading,
        ebook,
        loadEbook,
        list,
        pageNo,
        pdfloading,
        loadPageAppToken
      }}
    >
      {children}
    </EbooksContext.Provider>
  )
}

export const useEbooks = () => useContext(EbooksContext)
