import * as S from './styles'
import { Link, useHistory, useParams } from 'react-router-dom'
import { AllowedPagesTypes, ParamsType, allowedPages } from './config'
import { Spin, Input, Pagination } from 'antd'
import { useEffect, useState } from 'react'
import { useVideos } from 'contexts/videos'
import { useUsers } from 'contexts/user'
import EmptyData from 'components/atoms/EmptyData'

const { Search } = Input

function Boards() {
  const { goldLoading, isGold } = useUsers()
  const { list, loadList = () => true, loading, pageNo } = useVideos()
  const [search, setSearch] = useState('')
  const [thisPage, setThisPage] = useState<AllowedPagesTypes>()
  const { page } = useParams<ParamsType>()
  const history = useHistory()

  useEffect(() => {
    if (!goldLoading) {
      const index = allowedPages.findIndex((allowed) => allowed.path === page)
      if (index < 0) {
        history.push('/not-found')
      } else {
        setThisPage(allowedPages[index])
        loadList(String(allowedPages[index].selector), Boolean(isGold))
      }
    }
  }, [goldLoading, history, isGold, loadList, page])

  const onSearch = (data: string) => {
    setSearch(data)
    if (thisPage) {
      loadList(thisPage.name, Boolean(isGold), data)
    }
  }

  return (
    <S.Container>
      <S.Title>{thisPage?.name}</S.Title>
      <S.Description>{thisPage?.description}</S.Description>
      <S.SearchArea>
        <S.Input>
          <Search
            placeholder="Pesquise aqui"
            allowClear
            enterButton
            onSearch={onSearch}
          />
        </S.Input>
      </S.SearchArea>
      {loading ? (
        <S.LoadingWrapper>
          <Spin size="large" />
        </S.LoadingWrapper>
      ) : list.videos && list.videos.length === 0 ? (
        <EmptyData visible label="Sem dados" />
      ) : (
        <>
          <S.Files>
            {list.videos.map((video: any) => (
              <Link key={video.id} to={`/v/${thisPage?.path}/${video.id}`}>
                <S.File>
                  <S.Image src={video.video_image} />
                  <S.FileTitle title={video.name}>{video.name}</S.FileTitle>
                </S.File>
              </Link>
            ))}
          </S.Files>
          <S.Footer>
            <Pagination
              size="small"
              total={list.total}
              defaultPageSize={12}
              defaultCurrent={pageNo}
              current={pageNo}
              onChange={(page: number) =>
                loadList(
                  thisPage && thisPage.selector ? thisPage.selector : '',
                  Boolean(isGold),
                  search,
                  page
                )
              }
              hideOnSinglePage
            />
          </S.Footer>
        </>
      )}
    </S.Container>
  )
}

export default Boards
