import { notification } from 'antd'

export default function getIdFromLocalStorage(): undefined | string {
  const localstorage = localStorage.getItem('HORADAREDACAO_USER')

  if (!localstorage) {
    notification.error({
      message: 'Atenção',
      description:
        'Deslogue e logue novamente na aplicação para edição de sua conta'
    })
    return undefined
  }

  const storage = JSON.parse(localstorage)
  return storage.id
}
