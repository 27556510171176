/* eslint-disable @typescript-eslint/ban-types */
import { useState, useEffect } from 'react'
import {
  XAxis,
  LineChart,
  Line,
  ResponsiveContainer,
  CartesianGrid,
  YAxis
} from 'recharts'
import * as S from './styles'
import chartDatas from './datas'

type AreaTypes = {
  name: string
  lineColor?: string
  dotColor?: string
  serie: string
}

export type ChartProps = {
  title?: string
  datas?: any
  area?: AreaTypes[]
  isMoney?: boolean
}

const Chart = ({
  datas = chartDatas.series,
  area = chartDatas.areas
}: ChartProps) => {
  const [data, setData] = useState(datas)
  const [areas, setAreas] = useState(area)

  useEffect(() => {
    setData(datas)
    setAreas(area)
  }, [datas, area])

  return (
    <S.Wrapper>
      <ResponsiveContainer width="100%">
        <LineChart
          margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          data={data}
        >
          <CartesianGrid height={1} strokeWidth={0.4} />
          <XAxis dataKey="name" stroke="0.4" />
          <YAxis axisLine={false} width={20} allowDecimals={false} />

          {areas.map((props: any) => (
            <Line
              key={props.serie}
              type="monotone"
              dataKey={props.serie}
              stroke={props.lineColor}
              name={props.name}
              hide={props.hide}
              fill={props.dotColor}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </S.Wrapper>
  )
}

export default Chart
