export type SearchSelectOptions = {
  label: string
  value: string | number
}

export const formatter = (options: any) => {
  if (Array.isArray(options)) {
    if (typeof options[0] === 'number' || typeof options[0] === 'string')
      return options

    let isNumber = false
    const valuesNumber: number[] = []
    const valuesString: string[] = []

    options.forEach((option) => {
      if (Number.isInteger(option.value)) {
        valuesNumber.push(Number(option.value))
        isNumber = true
      } else {
        if (option.value) {
          valuesString.push(option.value.toString())
        }
      }
    })

    return isNumber ? valuesNumber : valuesString
  }

  if (typeof options === 'number' || typeof options === 'string') {
    return options
  }

  return options?.value
}

/**
 * Receive new Date to returns iso format
 * @param date 2021-10-20 23:54:20
 * @returns '2021-10-21T02:54:20.000Z'
 */
export const dateFormatter = (date: Date | undefined): string | undefined => {
  return date?.toISOString()
}

/**
 * Receive date string and time string to join in Date format to returns iso format
 * @param date "01/12/2021"
 * @param time "02:34:45"
 * @returns '2021-01-01T05:34:45.000Z'
 */
export const joinDateAndFormatter = (date: string, time: string): string => {
  const preDate = new Date(`${date} ${time}`)

  return preDate.toISOString()
}

/**
 * On works with dates it's normal to receive "9/5/2020" solution this problem, the function resolve this problem
 * @param number 9
 * @returns 09
 */
export const isLessTen = (number: number): string => {
  if (number < 10) return `0${number}`

  return String(number)
}
/**
 * Receives a Date and returns brazilian date format
 * @param date 202X-12-31 00:00:00
 * @returns 31/12/202X 00:00:00
 */
export const dateBrFormatter = (date: Date): string => {
  return new Date(date).toLocaleString('pt-BR', {
    timeZone: 'America/Sao_Paulo'
  })
}

type KeywordsFormatter = {
  key: string | number
}

export const keywordFormatter = (
  keywords: SearchSelectOptions[]
): KeywordsFormatter[] => {
  return keywords.map((keyword) => ({
    key: keyword.value
  }))
}

/**
 * this function will handle any string with brazilian values
 * @param value R$9.999,99
 * @returns 9999.99
 */
export const numberFormatter = (value: string | number): number => {
  if (typeof value === 'number') return value
  return Number(
    String(value)
      .replace(/[%R$.]+/gi, '')
      .replace(',', '.')
  )
}

/**
 * Receives a number and return string currency
 * @param value 9999.99
 * @returns R$9.999,99
 */
export const currencyBrFormatter = (value: number): string => {
  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

export const secondsToMinutes = (s: number): string => {
  let minutes = 0
  let seconds = 0

  if (!s) {
    return `00:00`
  }

  if (s > 60) {
    minutes = Math.floor(s / 60)
  }

  seconds = Math.floor(s % 60)

  return `${minutes < 10 ? '0' + minutes : minutes}:${
    seconds < 10 ? '0' + seconds : seconds
  }`
}

/**
 * Detects is iOS plataform
 * @returns boolean
 */
export const isiOS = (): boolean => {
  return (
    navigator.userAgent.includes('iPhone') ||
    navigator.userAgent.includes('iPad')
  )
}
