/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Route, Redirect } from 'react-router-dom'
import { isAuthenticated, logout } from 'services/userAuth'
import * as S from './styles'

type PrivateRouteType = {
  component: any
  path: string
  exact?: boolean
}

function PrivateRoute({
  component: Component,
  path = '/',
  exact = false,
  ...rest
}: PrivateRouteType) {
  !isAuthenticated() && logout()

  return (
    <S.Wrapper>
      <S.AntdConfigProvider>
        <S.Sidebar />
        <S.Container>
          <S.Header />
          <S.Content>
            <Route
              {...rest}
              render={(props) =>
                isAuthenticated() ? (
                  <Component {...props} path={path} exact={exact} />
                ) : (
                  <Redirect
                    to={{ pathname: '/login', state: { from: props.location } }}
                  />
                )
              }
            />
          </S.Content>
        </S.Container>
      </S.AntdConfigProvider>
    </S.Wrapper>
  )
}

export default PrivateRoute
