import * as S from './styles'
import { Link } from 'react-router-dom'

import { Input, Pagination, Spin } from 'antd'
import { useMarathon } from 'contexts/marathon'
import { useEffect, useState } from 'react'
import EmptyData from 'components/atoms/EmptyData'

const { Search } = Input

function Podcards() {
  const { list, loadList = () => true, loading, pageNo } = useMarathon()
  const [search, setSearch] = useState('')

  useEffect(() => {
    loadList()
  }, [loadList])

  const onSearch = (data: string) => {
    setSearch(data)

    loadList(data)
  }

  return (
    <S.Container>
      <S.Title>Maratona</S.Title>
      <S.Description>
        42 dias antes do ENEM receba diariamente temas e podcasts para exercitar
        sua redação
      </S.Description>
      <S.SearchArea>
        <S.Input>
          <Search
            placeholder="Pesquise aqui"
            allowClear
            enterButton
            onSearch={onSearch}
          />
        </S.Input>
      </S.SearchArea>
      {loading ? (
        <S.LoadingWrapper>
          <Spin size="large" />
        </S.LoadingWrapper>
      ) : list.marathons && list.marathons.length === 0 ? (
        <EmptyData visible label="Sem dados" />
      ) : (
        <>
          <S.Files>
            {list.marathons.map((marathon: any) => (
              <Link key={marathon.id} to={`/maratona/${marathon.id}`}>
                <S.File title={marathon.name}>
                  <S.Image src={marathon.image} />
                  <S.FileTitle>{marathon.name}</S.FileTitle>
                </S.File>
              </Link>
            ))}
          </S.Files>
          <S.Footer>
            <Pagination
              size="small"
              total={list.total}
              defaultPageSize={12}
              defaultCurrent={pageNo}
              current={pageNo}
              onChange={(page: number) => loadList(search, page)}
              hideOnSinglePage
            />
          </S.Footer>
        </>
      )}
    </S.Container>
  )
}

export default Podcards
