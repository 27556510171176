import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export { default as Home } from './Home'
export { default as Login } from './Login'
export { default as Register } from './Register'
export { default as ForgotPassword } from './ForgotPassword'
export { default as ChangePassword } from './ChangePassword'
export { default as Configuration } from './Configuration'
export { default as Dashboard } from './Dashboard'
export { default as Ebooks } from './Ebooks'
export { default as Boards } from './Videos'
export { default as PDFViewer } from './Ebooks/PdfViewer'
export { default as BoardViewer } from './Videos/Viewer'
export { default as Podcasts } from './Podcasts'
export { default as PodcastsListner } from './Podcasts/Listner'
export { default as Marathon } from './Marathons'
export { default as MarathonViewer } from './Marathons/Viewer'
export { default as Composition } from './Composition'
export { default as Game } from './Game'
export { default as GamePlay } from './Game/Play'
export { default as WelcomeVideo } from './WelcomeVideo'

export { default as Error404 } from './Error404'
export { default as AppPDF } from './AppPDF'
export { default as AppPDF2 } from './AppPDF2'

function Index() {
  const history = useHistory()

  useEffect(() => {
    const token = localStorage.getItem('HORADAREDACAO_AUTH')

    if (token) {
      history.push('/home')
    } else {
      history.push('/login')
    }
  }, [history])

  return <div />
}

export default Index
