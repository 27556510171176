import { useProgress } from 'contexts/progress'
import * as S from './styles'

function HeaderLoader() {
  const { progress } = useProgress()

  return (
    <S.Loader progress={progress}>
      <S.Ghost />
    </S.Loader>
  )
}

export default HeaderLoader
