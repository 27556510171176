import { createContext, useContext, useState, useCallback } from 'react'
import api from 'services/api'
import { useUsers } from './user'

export type GameProps = {
  children?: React.ReactNode
}

type GameTypes = {
  loadList?: (search?: string, page?: number) => void
  list: {
    themes: any
    total: number
  }
  loading: boolean
  pageLoading: boolean
  game: any
  pageNo: number
  loadPage?: (id: string) => void
  saveGrade?: (grade: number) => void
}

const gameContextDefaultValue: GameTypes = {
  list: {
    themes: [],
    total: 0
  },
  game: undefined,
  loading: true,
  pageLoading: true,
  pageNo: 1
}

const GameContext = createContext<GameTypes>(gameContextDefaultValue)

export const GameProvider = ({ children }: GameProps) => {
  const [loading, setLoading] = useState(true)
  const [pageLoading, setPageLoading] = useState(true)
  const [list, setList] = useState<any>(gameContextDefaultValue.list)
  const [game, setGame] = useState<any>(undefined)
  const [pageNo, setPage] = useState(1)
  const { actionActivity = () => true } = useUsers()

  const loadList = useCallback(async (search?: string, page = 1) => {
    setLoading(true)

    let searchKey = ''
    if (search) {
      searchKey = `&search=${search}`
    }
    setPage(page)
    const response = await api.get(
      `/themes?page=${page}&size=${15}${searchKey}`
    )
    if (response.status >= 400) return

    setList(response.data)
    setLoading(false)
  }, [])

  const loadPage = useCallback(async (id: string) => {
    setPageLoading(true)

    const response = await api.get(`/themes/${id}`)
    if (response.status >= 400) return

    setGame(response.data)
    setPageLoading(false)
  }, [])

  const saveGrade = useCallback(
    async (grade: number) => {
      await api.post('/themes/save-grade', {
        grade
      })

      actionActivity()
    },
    [actionActivity]
  )

  return (
    <GameContext.Provider
      value={{
        loadList,
        loading,
        game,
        loadPage,
        list,
        pageNo,
        pageLoading,
        saveGrade
      }}
    >
      {children}
    </GameContext.Provider>
  )
}

export const useGames = () => useContext(GameContext)
