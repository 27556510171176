import * as S from './styles'
import { useForm, Controller } from 'react-hook-form'
import { Input, Button } from 'antd'

import { Link } from 'react-router-dom'
import { useAuth } from 'contexts/login'

function Login() {
  const { forgotPassword = () => true, loading } = useAuth()
  const { handleSubmit, control } = useForm()

  return (
    <S.Wrapper>
      <S.Central>
        <S.FormWrapper onSubmit={handleSubmit(forgotPassword)}>
          <S.Logo src="/logo-l.png" alt="logo" />
          <S.HoraRedacao>Hora da Redação</S.HoraRedacao>
          <S.Description>
            Esqueceu a sua senha? Sem problemas! Digite seu email que enviaremos
            um link para a alteração da senha!
          </S.Description>
          <S.Field>
            <S.Label>Email</S.Label>
            <Controller
              as={<Input placeholder="Digite seu email" />}
              control={control}
              name="email"
            />
          </S.Field>
          <S.FormFooter>
            <Button
              loading={loading}
              disabled={loading}
              htmlType="submit"
              type="primary"
            >
              Enviar
            </Button>
          </S.FormFooter>
        </S.FormWrapper>
      </S.Central>
      <S.CreateAnAccount>
        <S.QuestionCreate>Lembrou da senha?</S.QuestionCreate>
        <Link to="/login">
          <Button disabled={loading}>Faça o login</Button>
        </Link>
      </S.CreateAnAccount>
    </S.Wrapper>
  )
}

export default Login
