import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { notification } from 'antd'
import { logout } from './userAuth'
import env from './env'

export const baseURL = env.API

const Api = axios.create({
  baseURL,
  timeout: 500000
})

type ApiRequestType = {
  get: (url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<any>>
  post: (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<any>>
  delete: (
    url: string,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<any>>
  put: (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<any>>
  patch: (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<any>>
}

type ErrorResponseType = {
  status: number
  data: {
    message: string
    err?: string
  }
}

const checkError = ({ status, data }: ErrorResponseType) => {
  if (status >= 400) {
    notification.error({
      message: 'Atenção',
      description: data.message
    })

    if (status === 401) {
      const { pathname } = window.location

      if (!['/login'].includes(pathname)) {
        logout()
      }
    } else {
      console.error(data.err)
    }
  }
}

export const setHeaderApiToken = () => {
  const token = localStorage.getItem('HORADAREDACAO_AUTH')

  if (token && !Api.defaults.headers.Authorization) {
    Api.defaults.headers.Authorization = `Bearer ${token}`
  }
}

const api: ApiRequestType = {
  get: async (
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> => {
    try {
      setHeaderApiToken()
      const token = localStorage.getItem('HORADAREDACAO_AUTH')
      const response = await Api.get(url, {
        ...config,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response
    } catch (error: any) {
      checkError(error.response)
      return error.response
    }
  },
  post: async (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> => {
    try {
      setHeaderApiToken()
      const response = await Api.post(url, data, config)
      return response
    } catch (error: any) {
      checkError(error.response)
      return error.response
    }
  },
  put: async (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> => {
    try {
      setHeaderApiToken()
      const response = await Api.put(url, data, config)
      return response
    } catch (error: any) {
      checkError(error.response)
      return error.response
    }
  },
  patch: async (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> => {
    try {
      setHeaderApiToken()
      const response = await Api.patch(url, data, config)
      return response
    } catch (error: any) {
      checkError(error.response)
      return error.response
    }
  },
  delete: async (
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> => {
    try {
      setHeaderApiToken()
      const response = await Api.delete(url, config)
      return response
    } catch (error: any) {
      checkError(error.response)
      return error.response
    }
  }
}

export default api
