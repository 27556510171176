export const logout = () => {
  localStorage.removeItem('HORADAREDACAO_AUTH')
  localStorage.removeItem('HORADAREDACAO_USER')

  window.location.href = '/'
}

export const isAuthenticated = (): boolean => {
  const token = localStorage.getItem('HORADAREDACAO_AUTH')

  return !!token
}
