import * as S from './styles'
import Chart from 'components/atoms/Chart'
import { useDashboard } from 'contexts/dashboard'
import { useEffect } from 'react'
import { Spin } from 'antd'

function Dashboard() {
  const {
    dashboardData,
    loadingDash,
    loadDash = () => true,
    noContent
  } = useDashboard()

  useEffect(() => {
    loadDash()
  }, [loadDash])

  if (loadingDash) {
    return (
      <S.LoadingWrapper>
        <Spin size="large" />
      </S.LoadingWrapper>
    )
  }

  return (
    <S.Container>
      <S.Title noContent={noContent}>
        {noContent
          ? `Não há dados para ser mostrados no momento,\nrealize uma atividade!`
          : 'Confira seu desempenho no Hora da Redação'}
      </S.Title>
      {!noContent && (
        <>
          <S.Section>
            <S.BigCard>
              <S.Card>
                <S.Item>
                  <S.MonitorIcon />
                  <S.ItemTitle>Vídeos</S.ItemTitle>
                  <S.Number>{dashboardData.videos}</S.Number>
                </S.Item>
                <S.Item>
                  <S.ReadIcon />
                  <S.ItemTitle>Textos</S.ItemTitle>
                  <S.Number>{dashboardData.ebooks}</S.Number>
                </S.Item>
                <S.Item>
                  <S.PhonesIcon />
                  <S.ItemTitle>Podcasts</S.ItemTitle>
                  <S.Number>{dashboardData.podcasts}</S.Number>
                </S.Item>
              </S.Card>
            </S.BigCard>
            <S.MiniCards>
              <S.MiniCard>
                <S.Card className="center">
                  <S.Item>
                    <S.CompositionIcon />
                    <S.ItemTitle className="min-text">Você fez</S.ItemTitle>
                    <S.Number>{dashboardData.games}</S.Number>
                    <S.ItemTitle className="min-text">
                      Jogos de Redação
                    </S.ItemTitle>
                  </S.Item>
                </S.Card>
              </S.MiniCard>
              <S.MiniCard>
                <S.Card className="center">
                  <S.Item>
                    <S.ChartIcon />
                    <S.ItemTitle className="min-text">Sua média é</S.ItemTitle>
                    <S.Number>{dashboardData.average}</S.Number>
                    <S.ItemTitle className="min-text">pontos</S.ItemTitle>
                  </S.Item>
                </S.Card>
              </S.MiniCard>
            </S.MiniCards>
          </S.Section>
          <S.Section>
            <S.BigCard className="complete">
              <S.Card className="no-pad chart">
                <S.ChartTitle>
                  Conteúdos vistos/ouvidos nos últimos 7 dias
                </S.ChartTitle>
                <Chart datas={dashboardData.activities} />
              </S.Card>
            </S.BigCard>
          </S.Section>
        </>
      )}
    </S.Container>
  )
}

export default Dashboard
