import * as S from './styles'
import { useForm, Controller } from 'react-hook-form'
import { Input, Button } from 'antd'
import { Link } from 'react-router-dom'
import { useAuth } from 'contexts/login'

function Register() {
  const { handleSubmit, control } = useForm()
  const { register = () => true, loading } = useAuth()

  return (
    <S.Wrapper>
      <S.Central>
        <S.FormWrapper onSubmit={handleSubmit(register)}>
          <S.Logo src="/logo-l.png" alt="logo" />
          <S.HoraRedacao>Hora da Redação</S.HoraRedacao>
          <S.Description>
            Crie uma conta para ter acesso ao conteúdo
          </S.Description>
          <S.Field>
            <S.Label>Nome completo *</S.Label>
            <Controller
              as={<Input required placeholder="Digite seu nome completo" />}
              control={control}
              name="name"
            />
          </S.Field>
          <S.Field>
            <S.Label>Email *</S.Label>
            <Controller
              as={<Input required placeholder="Digite seu email" />}
              control={control}
              name="email"
            />
          </S.Field>
          <S.Field>
            <S.Label>Senha *</S.Label>
            <Controller
              as={<Input.Password required placeholder="Digite sua senha" />}
              control={control}
              name="password"
            />
          </S.Field>
          <S.Field>
            <S.Label>Cupom</S.Label>
            <Controller
              as={<Input placeholder="Digite o código do cupom" />}
              control={control}
              name="code"
            />
          </S.Field>
          <S.FormFooter>
            <Button
              loading={loading}
              disabled={loading}
              htmlType="submit"
              type="primary"
            >
              Entrar
            </Button>
          </S.FormFooter>
        </S.FormWrapper>
      </S.Central>
      <S.CreateAnAccount>
        <S.QuestionCreate>Já tem uma conta?</S.QuestionCreate>
        <Link to="/login">
          <Button disabled={loading}>Faça o login</Button>
        </Link>
      </S.CreateAnAccount>
    </S.Wrapper>
  )
}

export default Register
