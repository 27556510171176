export const theme = {
  colors: {
    primary: {
      hex: '#924030',
      rgb: '146, 64, 48'
    },
    secondary: {
      hex: '#001529',
      rgb: '255, 178, 49'
    },
    text: {
      clear: '#686868',
      normal: '#383838',
      bold: '#232323'
    },

    medal: {
      gold: '#C9B037',
      silver: '#B4B4B4',
      bronze: '#AD8A56'
    }
  },
  boxShadow: {
    card: '2px 2px 10px rgba(0, 0, 0, 0.15)'
  },
  zIndexes: {
    searchWrapper: 2000,
    header: 1000,
    cookies: 500,
    headerMenu: 1002,
    listMenu: 1001
  },
  window: {
    maxWidth: '1366px'
  }
}
