import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
`

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Embed = styled.embed`
  height: 100%;
`

export const Title = styled.div`
  width: 100%;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.text.bold};
  font-weight: 600;
  padding: 20px;
`

export const Images = styled.div`
  width: 100%;
  max-width: 1440px;
  position: relative;
  margin: 0 auto;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 5px 0;
`

export const Protector = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
`

export const Image = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;
`

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`
