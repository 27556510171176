import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  padding: 10px;
`

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  width: 100%;
  margin: 20px auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Audio = styled.audio`
  width: 100%;
  background: transparent;
`

export const Source = styled.source``

export const Title = styled.div`
  width: 100%;
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.bold};
  font-weight: 600;
  padding: 20px;
`

export const Image = styled.img`
  object-fit: contain;
  max-height: 400px;
  max-width: 400px;
  width: 100%;
`
