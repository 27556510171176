import { Button } from 'antd'
import { useGames } from 'contexts/game'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as S from './styles'

function Game() {
  const { loadList = () => true, loading, list, pageNo } = useGames()

  useEffect(() => {
    loadList()
  }, [loadList])

  return (
    <S.Container>
      <S.Title>Jogos</S.Title>
      <S.Description>
        Encare o desafio de corrigir uma redação. Leia cada parágrafo
      </S.Description>
      <S.List
        loading={loading}
        dataSource={list ? list.themes : []}
        rowKey="id"
        onChange={(pagination) => loadList('', pagination.current)}
        pagination={{
          pageSize: 500,
          hideOnSinglePage: true,
          size: 'small',
          total: 500,
          current: pageNo
        }}
      >
        <S.Column
          title="Título"
          dataIndex="id"
          key="id"
          render={(id, record, index) => <span>Tema{` ${index + 1}`}</span>}
        />
        <S.Column title="Descrição" dataIndex="name" key="name" />
        <S.Column
          title="Jogar"
          dataIndex="id"
          key="id"
          render={(id) => (
            <Link to={`/jogo/${id}`}>
              <Button>Jogar</Button>
            </Link>
          )}
        />
      </S.List>
    </S.Container>
  )
}

export default Game
