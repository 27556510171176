import { createContext, useContext, useState, useCallback } from 'react'
import api from 'services/api'
import { useUsers } from './user'

export type VideoProps = {
  children?: React.ReactNode
}

type VideoTypes = {
  loadList?: (
    category: string,
    isGold: boolean,
    search?: string,
    page?: number
  ) => void
  list: any
  loading: boolean
  pageLoading: boolean
  video: any
  pageNo: number
  loadPage?: (id: string) => void
}

const videoContextDefaultValue: VideoTypes = {
  list: undefined,
  video: undefined,
  loading: true,
  pageLoading: true,
  pageNo: 1
}

const VideosContext = createContext<VideoTypes>(videoContextDefaultValue)

export const VideosProvider = ({ children }: VideoProps) => {
  const [loading, setLoading] = useState(true)
  const [pageLoading, setPdfLoading] = useState(true)
  const [list, setList] = useState<any>(undefined)
  const [video, setVideo] = useState<any>(undefined)
  const [pageNo, setPage] = useState(1)
  const { actionActivity = () => true } = useUsers()

  const loadList = useCallback(
    async (category: string, isGold: boolean, search?: string, page = 1) => {
      setLoading(true)

      const filter: any = {
        category,
        gd: isGold,
        page,
        size: 12
      }

      if (search) {
        filter.search = search
      }

      const url = new URLSearchParams(filter).toString()

      setPage(page || 1)
      const response = await api.get(`/videos?${url}`)
      if (response.status >= 400) return

      setList(response.data)
      setLoading(false)
    },
    []
  )

  const loadPage = useCallback(
    async (id: string) => {
      setPdfLoading(true)

      const response = await api.get(`/videos/${id}`)
      if (response.status >= 400) return

      setVideo(response.data)
      setPdfLoading(false)

      await api.post('/video-watched', {
        video_id: id
      })
      actionActivity()
    },
    [actionActivity]
  )

  return (
    <VideosContext.Provider
      value={{
        loadList,
        loading,
        video,
        loadPage,
        list,
        pageNo,
        pageLoading
      }}
    >
      {children}
    </VideosContext.Provider>
  )
}

export const useVideos = () => useContext(VideosContext)
