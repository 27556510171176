import { createContext, useContext, useState, useCallback } from 'react'
import api from 'services/api'

export type DashboardProps = {
  children?: React.ReactNode
}

type DashboardType = {
  loadingL1: boolean
  loadingL2: boolean
  loadingL3: boolean
  loader?: () => void
  loadDash?: () => void
  data1: any
  data2: any
  data3: any
  noContent: boolean
  loadingDash: boolean
  dashboardData: any
}

const dashboardContextDefaultValue: DashboardType = {
  loadingL1: true,
  loadingL2: true,
  loadingL3: true,
  data1: undefined,
  data2: undefined,
  data3: undefined,
  noContent: false,
  loadingDash: true,
  dashboardData: undefined
}

const DashboardContext = createContext<DashboardType>(
  dashboardContextDefaultValue
)

export const DashboardProvider = ({ children }: DashboardProps) => {
  const [loadingL1, setLoadingL1] = useState(true)
  const [data1, setData1] = useState<any>()
  const [data2, setData2] = useState<any>()
  const [data3, setData3] = useState<any>()
  const [loadingL2, setLoadingL2] = useState(true)
  const [loadingL3, setLoadingL3] = useState(true)
  const [loadingDash, setLoadingDash] = useState(true)
  const [dashboardData, setDashboardData] = useState<any>()
  const [noContent, setNoContent] = useState(false)

  const loader = useCallback(async () => {
    setLoadingL1(true)
    setLoadingL2(true)
    setLoadingL3(true)

    const r1 = await api.get('/home/l1')
    if (r1.status >= 400) return
    setData1(r1.data)
    setLoadingL1(false)

    const r2 = await api.get('/home/l2')
    if (r2.status >= 400) return
    setData2(r2.data)
    setLoadingL2(false)

    const r3 = await api.get('/home/l3')
    if (r3.status >= 400) return
    setData3(r3.data)
    setLoadingL3(false)
  }, [])

  const loadDash = useCallback(async () => {
    setLoadingDash(true)
    setNoContent(false)

    const response = await api.get('/home/dashboard')
    if (response.status >= 400) return

    if (response.status === 204) {
      setNoContent(true)
    } else {
      setDashboardData(response.data)
    }
    setLoadingDash(false)
  }, [])

  return (
    <DashboardContext.Provider
      value={{
        loadingL1,
        loadingL2,
        loadingL3,
        data1,
        data2,
        data3,
        loadDash,
        noContent,
        loader,
        loadingDash,
        dashboardData
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboard = () => useContext(DashboardContext)
