import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 600px;
  position: relative;
`
export const Images = styled.div<{ bg?: string }>`
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background-image: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
