import * as S from './styles'
import { useForm, Controller } from 'react-hook-form'
import { Input, Button, notification } from 'antd'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAuth } from 'contexts/login'

function Login() {
  const history = useHistory()
  const [token, setToken] = useState<undefined | string>(undefined)
  const { changePassword = () => true } = useAuth()
  const { handleSubmit, control } = useForm()

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const queryToken = query.get('token')

    if (!queryToken) {
      history.push('/login')
    }

    setToken(String(queryToken))
  }, [history])

  const onSubmit = (data: any) => {
    const { password, password2 } = data
    if (password !== password2) {
      notification.warn({
        message: 'Atenção',
        description: 'As senhas não conferem, revise'
      })
      return
    }

    changePassword({ ...data, id: token })
  }

  return (
    <S.Wrapper>
      <S.Central>
        <S.FormWrapper onSubmit={handleSubmit(onSubmit)}>
          <S.Logo src="/logo-l.png" alt="logo" />
          <S.HoraRedacao>Hora da Redação</S.HoraRedacao>
          <S.Description>
            Cadastre uma nova senha! <br />
          </S.Description>
          <S.Field>
            <S.Label>Senha</S.Label>
            <Controller
              as={<Input.Password placeholder="Digite sua senha" />}
              control={control}
              name="password"
            />
          </S.Field>
          <S.Field>
            <S.Label>Repita a senha</S.Label>
            <Controller
              as={<Input.Password placeholder="Digite novamente a senha" />}
              control={control}
              name="password2"
            />
          </S.Field>
          <S.FormFooter>
            <Button htmlType="submit" type="primary">
              Salvar
            </Button>
          </S.FormFooter>
        </S.FormWrapper>
      </S.Central>
    </S.Wrapper>
  )
}

export default Login
