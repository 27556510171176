import * as S from './styles'

type ParagraphType = {
  paragraphs?: any
  title?: string
  onSelect?: (paragraph: any) => void
}

function Paragraphs({
  paragraphs,
  title,
  onSelect = () => true
}: ParagraphType) {
  return (
    <S.Wrapper>
      {paragraphs.map((paragraph: any, index: number) => (
        <S.Part key={paragraph.id} onClick={() => onSelect(paragraph)}>
          <S.Title>{`${title} ${index + 1}`}</S.Title>
          <S.Paragraph>{paragraph.paragraph}</S.Paragraph>
        </S.Part>
      ))}
    </S.Wrapper>
  )
}

export default Paragraphs
