export type ParamsType = {
  page: string
}

export type AllowedPagesTypes = {
  name: string
  path: string
  description: string
}

export const allowedPages: AllowedPagesTypes[] = [
  {
    name: 'Temas Semanais',
    path: 'temas-semanais',
    description: 'Utilize os temas semanais para inspirar suas redações'
  },
  {
    name: 'PPT Interdisciplinar',
    path: 'ppt-interdisciplinar',
    description:
      'Enriqueça sua argumentação com esse conteúdo exclusivo que preparamos para você'
  },
  {
    name: 'E-books',
    path: 'ebooks',
    description: 'E-books das mais variados assuntos para aprimorar sua redação'
  },
  {
    name: 'Redações Exemplares',
    path: 'redacoes-exemplares',
    description: 'Redações em alto nível para você ler, analisar e se inspirar'
  }
]
