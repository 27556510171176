import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
`

export const Content = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
`

export const Field = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 13px;

  &.min-side {
    width: 30%;

    @media (max-width: 991px) {
      width: 100%;
    }
  }
`

export const Label = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.normal};
`

export const DoubleField = styled.div`
  display: flex;
  gap: 0 20px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const ChangePasswordWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`
export const Text = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.normal};
`
