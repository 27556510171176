import { Route, Switch } from 'react-router-dom'
import PrivateRoute from 'components/molecules/PrivateRoute'
import PublicRoute from 'components/molecules/PublicRoute'
import * as Page from 'pages'

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Page.default} />

      <Route path="/not-found" exact component={Page.Error404} />
      <Route path="/app/:id/:user" exact component={Page.AppPDF} />
      <Route path="/ebook/:id/:user" exact component={Page.AppPDF2} />
      <PublicRoute path="/login" exact component={Page.Login} />
      <PublicRoute
        path="/esqueci-minha-senha"
        exact
        component={Page.ForgotPassword}
      />
      <PublicRoute path="/nova-senha" exact component={Page.ChangePassword} />
      <PublicRoute path="/cadastre-se" exact component={Page.Register} />

      <PrivateRoute path="/home" exact component={Page.Home} />
      <PrivateRoute path="/dashboard" exact component={Page.Dashboard} />

      <PrivateRoute
        path="/veja-como-usar-a-plataforma"
        exact
        component={Page.WelcomeVideo}
      />

      <PrivateRoute path="/e/:page" exact component={Page.Ebooks} />
      <PrivateRoute path="/e/:page/:id" exact component={Page.PDFViewer} />

      <PrivateRoute path="/v/:page" exact component={Page.Boards} />
      <PrivateRoute path="/v/:page/:id" exact component={Page.BoardViewer} />

      <PrivateRoute path="/jogo" exact component={Page.Game} />
      <PrivateRoute path="/jogo/:id" exact component={Page.GamePlay} />

      <PrivateRoute
        path="/faca-sua-redacao"
        exact
        component={Page.Composition}
      />

      <PrivateRoute path="/podcasts" exact component={Page.Podcasts} />
      <PrivateRoute
        path="/podcasts/:id"
        exact
        component={Page.PodcastsListner}
      />

      <PrivateRoute path="/maratona" exact component={Page.Marathon} />
      <PrivateRoute
        path="/maratona/:id"
        exact
        component={Page.MarathonViewer}
      />

      <PrivateRoute
        path="/configuracoes"
        exact
        component={Page.Configuration}
      />

      <Route component={Page.Error404} />
    </Switch>
  )
}
