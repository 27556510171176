import Routes from 'Routes'
import { theme } from 'styles/theme'
import { ThemeProvider as StyledProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import GlobalStyles from 'styles/globalStyles'
import { AuthProvider } from 'contexts/login'
import { ProgressProvider } from 'contexts/progress'
import { UserProvider } from 'contexts/user'
import { DashboardProvider } from 'contexts/dashboard'
import { EbooksProvider } from 'contexts/ebooks'
import { VideosProvider } from 'contexts/videos'
import { PodcastsProvider } from 'contexts/podcasts'
import { MarathonProvider } from 'contexts/marathon'
import { GameProvider } from 'contexts/game'
import { CompositionsProvider } from 'contexts/compositions'

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ProgressProvider>
          <UserProvider>
            <DashboardProvider>
              <EbooksProvider>
                <VideosProvider>
                  <PodcastsProvider>
                    <MarathonProvider>
                      <GameProvider>
                        <CompositionsProvider>
                          <StyledProvider theme={theme}>
                            <GlobalStyles />
                            <Routes />
                          </StyledProvider>
                        </CompositionsProvider>
                      </GameProvider>
                    </MarathonProvider>
                  </PodcastsProvider>
                </VideosProvider>
              </EbooksProvider>
            </DashboardProvider>
          </UserProvider>
        </ProgressProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
