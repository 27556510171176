import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Spin, Tooltip } from 'antd'
import { useGames } from 'contexts/game'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Paragraphs from './Paragraphs'
import * as S from './styles'
import { Link } from 'react-router-dom'
import Correction from './Correction'

function Play() {
  const { loadPage = () => true, game, pageLoading } = useGames()
  const [partSelected, setPartSelected] = useState(1)
  const [paragraphs, setParagraphs] = useState<any>([])
  const [closedGame, setClosedGame] = useState(false)
  const [correction, setCorrection] = useState(false)
  const { id } = useParams<any>()

  useEffect(() => {
    loadPage(id)
  }, [id, loadPage])

  const handleSelectParagraph = useCallback(
    (paragraph: any) => {
      const texts = paragraphs || []
      texts.push(paragraph)

      setParagraphs(texts)

      setPartSelected((last) => last + 1)

      if (texts.length === 4) {
        setClosedGame(true)
      }
    },
    [paragraphs]
  )

  if (pageLoading) {
    return (
      <S.LoadingWrapper>
        <Spin size="large" />
      </S.LoadingWrapper>
    )
  }

  return (
    <S.Container>
      <S.Title>
        {game.name}

        {!closedGame && (
          <Tooltip
            placement="bottomRight"
            title="Clique no parágrafo para jogar"
          >
            <QuestionCircleOutlined />
          </Tooltip>
        )}
      </S.Title>
      {!closedGame && !correction ? (
        <>
          {partSelected === 1 ? (
            <Paragraphs
              paragraphs={game.introduction}
              title="Introdução"
              onSelect={(paragraph) => handleSelectParagraph(paragraph)}
            />
          ) : partSelected === 2 ? (
            <Paragraphs
              paragraphs={game.development_1}
              title="1º Desenvolvimento"
              onSelect={(paragraph) => handleSelectParagraph(paragraph)}
            />
          ) : partSelected === 3 ? (
            <Paragraphs
              paragraphs={game.development_2}
              title="2º Desenvolvimento"
              onSelect={(paragraph) => handleSelectParagraph(paragraph)}
            />
          ) : (
            <Paragraphs
              paragraphs={game.conclusion}
              title="Conclusão"
              onSelect={(paragraph) => handleSelectParagraph(paragraph)}
            />
          )}
        </>
      ) : (
        <>
          {!correction ? (
            <S.EndWrapper>
              {paragraphs.map((paragraph: any) => (
                <S.Paragraph key={paragraph.id}>
                  {paragraph.paragraph}
                </S.Paragraph>
              ))}

              <S.Buttons>
                <Link to="/jogo">
                  <Button>Apagar redação</Button>
                </Link>

                <Button onClick={() => setCorrection(true)} type="primary">
                  Corrigir Redação
                </Button>
              </S.Buttons>
            </S.EndWrapper>
          ) : (
            <Correction paragraphs={paragraphs} />
          )}
        </>
      )}
    </S.Container>
  )
}

export default Play
