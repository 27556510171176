import * as S from './styles'
import Carousel from 'components/atoms/Carousel'

type LoginWrapperTypes = {
  children?: React.ReactNode
}

function LoginWrapper({ children }: LoginWrapperTypes) {
  return (
    <S.Container>
      {children}
      <Carousel
        totalItems={5}
        hasArrow={false}
        hasPagination={false}
        delay={4000}
        auto
        itemsToShow={1}
      >
        <S.Images bg="/assets/login/cover4.jpeg" />
        <S.Images bg="/assets/login/cover1.png" />
        <S.Images bg="/assets/login/cover3.png" />
        <S.Images bg="/assets/login/cover2.jpeg" />
        <S.Images bg="/assets/login/cover5.png" />
      </Carousel>
    </S.Container>
  )
}

export default LoginWrapper
