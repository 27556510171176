import * as S from './styles'
import { ItemObject, RenderArrowProps } from 'react-elastic-carousel'
import { useRef, useCallback, useEffect, useState } from 'react'

type BreakPointsItemsTypes = {
  width: number
  itemsToShow: number
}

export type CarouselTypes = {
  auto?: boolean
  delay?: number
  hasArrow?: boolean
  hasPagination?: boolean
  itemsToShow?: number
  minToShow?: number
  showWidthLogs?: boolean
  maxToShow?: number
  totalItems: number
  breakPointsItems?: BreakPointsItemsTypes[]
  children: React.ReactNode
  paginationColor?: string
  arrowInside?: boolean
  onResize?: (width: number) => void
}

type PaginationType = {
  pages: any
  activePage: number
}

let timeout: NodeJS.Timeout

function Carousel({
  auto = true,
  delay = 5000,
  hasArrow = true,
  hasPagination = true,
  totalItems,
  itemsToShow,
  breakPointsItems,
  showWidthLogs = false,
  paginationColor = '#FFFFFF',
  onResize = () => true,
  arrowInside = true,
  children
}: CarouselTypes) {
  const [showItems, setShowItems] = useState(itemsToShow)
  const carouselRef: any = useRef(null)
  const screenWidth = (): number => window.innerWidth

  const resize = useCallback(() => {
    const width = screenWidth()

    if (breakPointsItems) {
      let itemsToDisplay
      breakPointsItems?.map((point) => {
        if (point.width < width) {
          itemsToDisplay = point.itemsToShow
        }
      })

      setShowItems(itemsToDisplay)
    }

    onResize(width)
    if (showWidthLogs) console.log({ window_width_carousel: width })
  }, [breakPointsItems, onResize, showWidthLogs])

  const goTo = (index: number) => {
    if (carouselRef && carouselRef.current) {
      carouselRef.current.goTo(index)
    }
  }

  useEffect(() => {
    setShowItems(itemsToShow)
  }, [itemsToShow])

  useEffect(() => {
    resize()
  }, [resize])

  const arrows = ({ type, onClick, isEdge }: RenderArrowProps) => {
    if (type === 'PREV')
      return (
        <S.LeftArrow
          isVisible={hasArrow}
          onClick={!isEdge ? onClick : undefined}
          disabled={isEdge}
          arrowInside={arrowInside}
        />
      )

    return (
      <S.RightArrow
        isVisible={hasArrow}
        onClick={!isEdge ? onClick : undefined}
        disabled={isEdge}
        arrowInside={arrowInside}
      />
    )
  }

  const pagination = ({ pages, activePage }: PaginationType) => {
    return (
      <S.Pagination isVisible={hasPagination}>
        {pages.map((page: number) => (
          <S.ItemPagination
            paginationColor={paginationColor}
            onClick={() => goTo(page)}
            isActive={activePage === page}
            key={page}
          />
        ))}
      </S.Pagination>
    )
  }

  const onChange = (
    currentItemObject: ItemObject,
    currentPageIndex: number
  ) => {
    if (currentPageIndex === totalItems - 1) {
      if (auto) {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          goTo(0)
        }, delay)
      }
    }
  }

  return (
    <S.Container hasPagination={hasPagination}>
      <S.Carousel
        ref={carouselRef}
        isRTL={false}
        enableAutoPlay={auto}
        autoPlaySpeed={delay}
        onChange={onChange}
        pagination={hasPagination}
        itemsToShow={showItems}
        renderPagination={pagination}
        renderArrow={arrows}
        onResize={resize}
      >
        {children}
      </S.Carousel>
    </S.Container>
  )
}

export default Carousel
