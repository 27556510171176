import { createContext, useContext, useState, useCallback } from 'react'
import api from 'services/api'
import { useUsers } from './user'

export type PodcastsProps = {
  children?: React.ReactNode
}

type PodcastsTypes = {
  loadList?: (isGold: boolean, search?: string, page?: number) => void
  list: any
  loading: boolean
  pageLoading: boolean
  podcast: any
  pageNo: number
  loadPage?: (id: string) => void
}

const podcastsContextDefaultValue: PodcastsTypes = {
  list: undefined,
  podcast: undefined,
  loading: true,
  pageLoading: true,
  pageNo: 1
}

const PodcastsContext = createContext<PodcastsTypes>(
  podcastsContextDefaultValue
)

export const PodcastsProvider = ({ children }: PodcastsProps) => {
  const [loading, setLoading] = useState(true)
  const [pageLoading, setPdfLoading] = useState(true)
  const [list, setList] = useState<any>(undefined)
  const [podcast, setPodcast] = useState<any>(undefined)
  const [pageNo, setPage] = useState(1)
  const { actionActivity = () => true } = useUsers()

  const loadList = useCallback(
    async (isGold: boolean, search?: string, page = 1) => {
      setLoading(true)

      const filter: any = {
        gd: isGold,
        page,
        size: 12
      }

      if (search) {
        filter.search = search
      }

      const url = new URLSearchParams(filter).toString()

      setPage(page || 1)
      const response = await api.get(`/podcasts?${url}`)
      if (response.status >= 400) return

      setList(response.data)
      setLoading(false)
    },
    []
  )

  const loadPage = useCallback(
    async (id: string) => {
      setPdfLoading(true)

      const response = await api.get(`/podcasts/${id}`)
      if (response.status >= 400) return

      setPodcast(response.data)
      setPdfLoading(false)

      await api.post('/podcasts-listened', {
        podcast_id: id
      })
      actionActivity()
    },
    [actionActivity]
  )

  return (
    <PodcastsContext.Provider
      value={{
        loadList,
        loading,
        podcast,
        loadPage,
        list,
        pageNo,
        pageLoading
      }}
    >
      {children}
    </PodcastsContext.Provider>
  )
}

export const usePodcasts = () => useContext(PodcastsContext)
