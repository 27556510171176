import axios from 'axios'
import { createContext, useContext, useState, useCallback } from 'react'
import api, { baseURL } from 'services/api'
import { useUsers } from './user'

export type MarathonProps = {
  children?: React.ReactNode
}

type MarathonTypes = {
  loadList?: (search?: string, page?: number) => void
  list: any
  loading: boolean
  pageLoading: boolean
  marathon: any
  pageNo: number
  loadPage?: (id: string) => void
  loadPageApp?: (id: string) => void
  loadPageAppToken?: (id: string, user: string) => void
}

const marathonContextDefaultValue: MarathonTypes = {
  list: undefined,
  marathon: undefined,
  loading: true,
  pageLoading: true,
  pageNo: 1
}

const MarathonContext = createContext<MarathonTypes>(
  marathonContextDefaultValue
)

export const MarathonProvider = ({ children }: MarathonProps) => {
  const [loading, setLoading] = useState(true)
  const [pageLoading, setPdfLoading] = useState(true)
  const [list, setList] = useState<any>(undefined)
  const [marathon, setMarathon] = useState<any>(undefined)
  const [pageNo, setPage] = useState(1)
  const { actionActivity = () => true } = useUsers()

  const loadList = useCallback(async (search?: string, page = 1) => {
    setLoading(true)

    const filter: any = {
      page,
      size: 12
    }

    if (search) {
      filter.search = search
    }

    const url = new URLSearchParams(filter).toString()
    setPage(page)
    const response = await api.get(`/marathon?${url}`)
    if (response.status >= 400) return

    setList(response.data)
    setLoading(false)
  }, [])

  const loadPage = useCallback(
    async (id: string) => {
      setPdfLoading(true)

      const response = await api.get(`/marathon/${id}`)
      if (response.status >= 400) return

      setMarathon(response.data)
      setPdfLoading(false)

      actionActivity()
    },
    [actionActivity]
  )

  const loadPageApp = useCallback(async (id: string) => {
    setLoading(true)

    const response = await axios.get(`${baseURL}/marathon/${id}`)
    if (response.status >= 400) return

    setMarathon(response.data)
    setLoading(false)
  }, [])

  const loadPageAppToken = useCallback(async (id: string, user: string) => {
    setLoading(true)

    const headers = {
      headers: {
        Authorization: `Bearer ${user}`
      }
    }

    const response = await axios.get(`${baseURL}/marathon/${id}`, headers)
    if (response.status >= 400) return

    setMarathon(response.data)
    setLoading(false)
  }, [])

  return (
    <MarathonContext.Provider
      value={{
        loadList,
        loading,
        marathon,
        loadPage,
        list,
        pageNo,
        pageLoading,
        loadPageApp,
        loadPageAppToken
      }}
    >
      {children}
    </MarathonContext.Provider>
  )
}

export const useMarathon = () => useContext(MarathonContext)
