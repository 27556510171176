import styled, { keyframes, css } from 'styled-components'
import { SkeletonTypes } from '.'

const load = keyframes`
  from {
    margin-left: -30%;
  }
  to {
    margin-left: 100%;
  }
`

export const Container = styled.div<SkeletonTypes>`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}px`};
  cursor: progress;
  background: ${({ bg }) => `${bg}`};
  overflow: hidden;
  border-radius: 6px;

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 50%;
      flex-shrink: 0;
    `}
`

export const Ghost = styled.div<SkeletonTypes>`
  width: 30%;
  height: 100%;
  background-image: ${({ bgGhost }) =>
    `linear-gradient(to right, transparent, ${bgGhost}, transparent)`};
  animation: ${load} linear infinite 1s;
`
