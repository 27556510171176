import { Button } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { secondsToMinutes } from 'utils/formatter'
import * as S from './styles'

type AudioPlayerType = {
  url: string
}

function AudioPlayer({ url }: AudioPlayerType) {
  const [trackIndex] = useState(0)
  const [trackProgress, setTrackProgress] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)

  // Refs
  const audioRef: any = useRef(new Audio(url))
  const intervalRef: any = useRef()
  const isReady: any = useRef(false)

  // Destructure for conciseness
  const { duration } = audioRef.current

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : '0%'
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  `

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current)

    intervalRef.current = setInterval(() => {
      if (!audioRef.current.ended) {
        setTrackProgress(audioRef.current.currentTime)
      }
    }, 1000)
  }

  const onScrub = (value: any) => {
    // Clear any timers already running
    clearInterval(intervalRef.current)
    audioRef.current.currentTime = value
    setTrackProgress(audioRef.current.currentTime)
  }

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true)
    }
    startTimer()
  }

  const jump = (value: number) => {
    clearInterval(intervalRef.current)
    audioRef.current.currentTime = audioRef.current.currentTime + value
    setTrackProgress(audioRef.current.currentTime)

    onScrubEnd()
  }

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play()
      startTimer()
    } else {
      audioRef.current.pause()
    }
  }, [isPlaying])

  const playPause = (bool: boolean) => {
    setIsPlaying(bool)
  }

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    audioRef.current.pause()

    audioRef.current = new Audio(url)
    setTrackProgress(audioRef.current.currentTime)

    if (isReady.current) {
      audioRef.current.play()
      setIsPlaying(true)
      startTimer()
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true
    }
  }, [trackIndex, url])

  useEffect(() => {
    return () => {
      audioRef.current.pause()
      clearInterval(intervalRef.current)
    }
  }, [])

  return (
    <S.Container>
      <S.Range
        type="range"
        value={trackProgress}
        step="1"
        min="0"
        max={duration || `${duration}`}
        className="progress"
        onChange={(e) => onScrub(e.target.value)}
        onMouseUp={onScrubEnd}
        onKeyUp={onScrubEnd}
        style={{ background: trackStyling }}
      />
      <S.Times>
        <S.Time>{duration ? secondsToMinutes(trackProgress) : ''}</S.Time>
        <S.Time>{secondsToMinutes(duration)}</S.Time>
      </S.Times>
      <div style={{ display: 'flex', gap: '0 10px', paddingLeft: 5 }}>
        <Button onClick={() => jump(-5)} type="dashed">
          <S.WardIcon />
        </Button>
        <Button
          style={{ width: '100%' }}
          type="primary"
          onClick={() => playPause(!isPlaying)}
        >
          {!isPlaying ? <S.Play /> : <S.Pause />}
        </Button>
        <Button onClick={() => jump(5)} type="dashed">
          <S.WardIcon className="forward-ic" />
        </Button>
      </div>
    </S.Container>
  )
}

export default AudioPlayer
