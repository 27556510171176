import * as S from './styles'
import { Empty } from 'antd'

type EmptyDataTypes = {
  label: string
  visible?: boolean
}

function EmptyData({ label, visible = false }: EmptyDataTypes) {
  return (
    <>
      {visible ? (
        <S.Container>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={label} />
        </S.Container>
      ) : (
        <div />
      )}
    </>
  )
}

export default EmptyData
