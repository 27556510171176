import * as S from './styles'
import { Tooltip, Button, Modal, Upload, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { dateBrFormatter } from 'utils/formatter'
import { imageFiles } from 'utils/fileExtensions'
import { useCompositions } from 'contexts/compositions'

function Composition() {
  const {
    data,
    formLoading,
    loading,
    handlePost = () => true,
    loadPage = () => true,
    handleDelete = () => true
  } = useCompositions()
  const [isVisible, setIsVisible] = useState(false)
  const [image, setImage] = useState<any>(undefined)

  useEffect(() => {
    loadPage()
  }, [loadPage])

  const handleChangeFile = (data: any) => {
    if (!data || data.fileList.length === 0) return data
    data.fileList[0].status = 'done'

    return data
  }

  const sendFile = () => {
    if (!image) {
      message.warn('Para enviar a redação, selecione o arquivo.')

      return
    }

    handlePost(image)
    setIsVisible(false)

    setTimeout(() => {
      setImage(undefined)
    }, 1500)
  }

  return (
    <S.Container>
      <Modal
        visible={isVisible}
        okText="Enviar"
        onCancel={() => {
          setIsVisible(false)
          setImage(undefined)
        }}
        onOk={() => sendFile()}
      >
        <S.WrapperModal>
          <Upload
            name="image"
            action=""
            fileList={image ? image.fileList : undefined}
            maxCount={1}
            onChange={(info) => setImage(handleChangeFile(info))}
            beforeUpload={(file) => {
              if (!imageFiles.includes(file.type)) {
                message.error(`${file.name} não é um arquivo válido`)
              }
              return imageFiles.includes(file.type) ? true : Upload.LIST_IGNORE
            }}
          >
            <Button icon={<UploadOutlined />}>Upload da Redação</Button>
          </Upload>
        </S.WrapperModal>
      </Modal>
      <S.Title>Redações</S.Title>
      <S.Description>
        Envie sua redação para correção em altíssimo nível
      </S.Description>
      <S.SearchArea>
        <Button
          loading={formLoading}
          onClick={() => setIsVisible(true)}
          type="primary"
        >
          Enviar Redação
        </Button>
      </S.SearchArea>
      <S.List
        dataSource={data}
        loading={loading}
        pagination={false}
        rowKey="id"
      >
        <S.Column
          title="Nota"
          dataIndex="grade"
          key="grade"
          render={(grade) => <>{grade ? <span>{grade}</span> : '-'}</>}
        />
        <S.Column
          title="Corrigido"
          dataIndex="correction_file"
          key="correction_file"
          render={(correction_file, data: any) => (
            <>
              {correction_file ? (
                <Tooltip
                  title={
                    data.updated_at
                      ? `Corrigido em: ${dateBrFormatter(data.updated_at)}`
                      : 'Data não encontrada'
                  }
                >
                  <S.CheckIcon />
                </Tooltip>
              ) : (
                <span>Aguardando correção</span>
              )}
            </>
          )}
        />
        <S.Column
          title="Ação"
          dataIndex="id"
          key="id"
          render={(id, data: any) => (
            <S.Actions>
              <S.Anchor href={data.composition_file}>
                <Button>Ver</Button>
              </S.Anchor>
              {data.correction_file && (
                <S.Anchor href={data.correction_file}>
                  <Button>Ver Correção</Button>
                </S.Anchor>
              )}
              <S.Confirm
                title="Tem certeza que deseja deletar?"
                onConfirm={() => handleDelete(id)}
                okText="Sim"
                cancelText="Não"
              >
                <Button>Apagar</Button>
              </S.Confirm>
            </S.Actions>
          )}
        />
      </S.List>
    </S.Container>
  )
}

export default Composition
