import * as S from './styles'
import Skeleton from 'components/atoms/Skeleton'

function MenuLoader() {
  return (
    <S.LoadingWrapper>
      <S.Loading>
        <Skeleton
          height={35}
          rounded
          width={35}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
        <Skeleton
          height={25}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
      </S.Loading>
      <S.Loading>
        <Skeleton
          height={35}
          rounded
          width={35}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
        <Skeleton
          height={25}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
      </S.Loading>
      <S.Loading>
        <Skeleton
          height={35}
          rounded
          width={35}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
        <Skeleton
          height={25}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
      </S.Loading>
      <S.Loading>
        <Skeleton
          height={35}
          rounded
          width={35}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
        <Skeleton
          height={25}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
      </S.Loading>
      <S.Loading>
        <Skeleton
          height={35}
          rounded
          width={35}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
        <Skeleton
          height={25}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
      </S.Loading>
      <S.Loading>
        <Skeleton
          height={35}
          rounded
          width={35}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
        <Skeleton
          height={25}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
      </S.Loading>
      <S.Loading>
        <Skeleton
          height={35}
          rounded
          width={35}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
        <Skeleton
          height={25}
          bg="rgba(255, 255, 255, 0.6)"
          bgGhost="rgba(255, 255, 255, 0.8)"
        />
      </S.Loading>
    </S.LoadingWrapper>
  )
}

export default MenuLoader
