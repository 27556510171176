import styled from 'styled-components'

export const Container = styled.div``

export const Card = styled.div<{ bg?: string }>`
  flex: 1;
  height: 270px;
  border-radius: 7px;
  position: relative;
  cursor: pointer;
  background-image: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 5px;
`

export const BgCard = styled.div`
  width: 100%;
  height: 100%;

  a {
    width: 100%;
    height: 100%;
  }
`

export const WrapperCard = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.4);
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-end;
`

export const Title = styled.span`
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  max-width: 100%;
  font-size: 18px;
  color: white;
`

export const SeeMore = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 700;
  font-size: 24px;
`
