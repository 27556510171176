import { message } from 'antd'
import { createContext, useContext, useState, useCallback } from 'react'
import api from 'services/api'
import { useUsers } from './user'

export type CompositionsProps = {
  children?: React.ReactNode
}

type CompositionsType = {
  loading: boolean
  formLoading: boolean
  loadPage?: () => void
  handlePost?: (data: any) => void
  handleDelete?: (id: any) => void
  data: any
}

const compositionsContextDefaultValue: CompositionsType = {
  loading: true,
  formLoading: true,
  data: []
}

const CompositionsContext = createContext<CompositionsType>(
  compositionsContextDefaultValue
)

export const CompositionsProvider = ({ children }: CompositionsProps) => {
  const [loading, setLoading] = useState(true)
  const { actionActivity = () => true } = useUsers()
  const [formLoading, setFormLoading] = useState(false)
  const [data, setData] = useState<any>([])

  const loadPage = useCallback(async () => {
    setLoading(true)

    const response = await api.get('/compositions')
    setLoading(false)

    if (response.status >= 400) return

    setData(response.data)
  }, [])

  const handlePost = useCallback(
    async (data: any) => {
      setFormLoading(true)

      const formData = new FormData()

      console.log(data.file)

      formData.append('composition_file', data.file.originFileObj)

      const response = await api.post('/compositions', formData)
      message.success('Redação enviada com sucesso!')
      setFormLoading(false)

      if (response.status >= 400) return

      loadPage()
      actionActivity()
    },
    [actionActivity, loadPage]
  )

  const handleDelete = useCallback(
    async (id: string) => {
      const response = await api.delete(`/compositions/${id}`)
      if (response.status >= 400) return
      message.success('Redação deletada com sucesso!')
      loadPage()
    },
    [loadPage]
  )

  return (
    <CompositionsContext.Provider
      value={{
        loading,
        loadPage,
        formLoading,
        handleDelete,
        handlePost,
        data
      }}
    >
      {children}
    </CompositionsContext.Provider>
  )
}

export const useCompositions = () => useContext(CompositionsContext)
