import * as S from './styles'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Spin, Input, Pagination } from 'antd'
import { AllowedPagesTypes, ParamsType, allowedPages } from './config'
import { useEbooks } from 'contexts/ebooks'
import { useUsers } from 'contexts/user'
import EmptyData from 'components/atoms/EmptyData'

const { Search } = Input

function Ebooks() {
  const { goldLoading, isGold } = useUsers()
  const { list, loadList = () => true, loading, pageNo } = useEbooks()
  const [search, setSearch] = useState('')
  const [thisPage, setThisPage] = useState<AllowedPagesTypes>()
  const { page } = useParams<ParamsType>()
  const history = useHistory()

  useEffect(() => {
    if (!goldLoading) {
      const index = allowedPages.findIndex((allowed) => allowed.path === page)
      if (index < 0) {
        history.push('/not-found')
      } else {
        setThisPage(allowedPages[index])
        loadList(allowedPages[index].name, Boolean(isGold))
      }
    }
  }, [goldLoading, history, isGold, loadList, page])

  const onSearch = (data: string) => {
    setSearch(data)
    if (thisPage) {
      loadList(thisPage.name, Boolean(isGold), data)
    }
  }

  return (
    <S.Container>
      <S.Title>{thisPage?.name}</S.Title>
      <S.Description>{thisPage?.description}</S.Description>
      <S.SearchArea>
        <S.Input>
          <Search
            placeholder="Pesquise aqui"
            allowClear
            enterButton
            onSearch={onSearch}
            disabled={loading}
          />
        </S.Input>
      </S.SearchArea>
      {loading ? (
        <S.LoadingWrapper>
          <Spin size="large" />
        </S.LoadingWrapper>
      ) : list.ebooks && list.ebooks.length === 0 ? (
        <EmptyData visible label="Sem dados" />
      ) : (
        <>
          <S.Files>
            {list.ebooks.map((ebook: any) => (
              <Link key={ebook.id} to={`/e/${thisPage?.path}/${ebook.id}`}>
                <S.File title={ebook.name}>
                  <S.Image src={ebook.image} />
                  <S.FileTitle>{ebook.name}</S.FileTitle>
                </S.File>
              </Link>
            ))}
          </S.Files>
          <S.Footer>
            <Pagination
              size="small"
              total={list.total}
              defaultPageSize={12}
              defaultCurrent={pageNo}
              current={pageNo}
              onChange={(page: number) =>
                loadList(
                  thisPage ? thisPage.name : '',
                  Boolean(isGold),
                  search,
                  page
                )
              }
              hideOnSinglePage
            />
          </S.Footer>
        </>
      )}
    </S.Container>
  )
}

export default Ebooks
