import * as S from './styles'
import Carousel from 'components/atoms/Carousel'
export * as Carousel from './styles'

export type DashboardCarouselType = {
  children?: React.ReactNode
}

function DashboardCarousel({ children }: DashboardCarouselType) {
  return (
    <S.Container>
      <Carousel
        totalItems={5}
        auto={false}
        itemsToShow={4}
        hasPagination={false}
        breakPointsItems={[
          {
            itemsToShow: 1,
            width: 350
          },
          {
            itemsToShow: 2,
            width: 520
          },
          {
            itemsToShow: 3,
            width: 991
          },
          {
            itemsToShow: 4,
            width: 1200
          }
        ]}
      >
        {children}
      </Carousel>
    </S.Container>
  )
}

export default DashboardCarousel
