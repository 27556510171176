import { Spin } from 'antd'
import { useEffect } from 'react'
import * as S from './styles'
import AudioPlayer from 'components/atoms/AudioPlayer'
import { usePodcasts } from 'contexts/podcasts'
import { useParams } from 'react-router-dom'
import { isiOS } from 'utils/formatter'

const Listner = () => {
  const { loadPage = () => true, podcast, pageLoading } = usePodcasts()

  const { id } = useParams<any>()

  useEffect(() => {
    loadPage(id)
  }, [id, loadPage])

  if (pageLoading) {
    return (
      <S.LoadingWrapper>
        <Spin size="large" />
      </S.LoadingWrapper>
    )
  }

  if (podcast.name === undefined || podcast.audio === null) {
    return (
      <S.LoadingWrapper>
        <h2>Áudio não encontrado</h2>
      </S.LoadingWrapper>
    )
  }

  return (
    <S.Container>
      <S.Wrapper>
        <S.Image src={podcast.image} />
        <S.Title>{podcast.name}</S.Title>
        {isiOS() ? (
          <S.Audio controls>
            <source src={podcast.audio} type="audio/mpeg" />
            Your browser does not support the audio tag.
          </S.Audio>
        ) : (
          <AudioPlayer url={podcast.audio} />
        )}
      </S.Wrapper>
    </S.Container>
  )
}

export default Listner
