import { Route } from 'react-router-dom'
import LoginWrapper from 'components/molecules/LoginWrapper'

type PublicRouterType = {
  component: any
  path: string
  exact?: boolean
}

function PublicRouter({
  component: Component,
  path = '/',
  exact = false,
  ...rest
}: PublicRouterType) {
  return (
    <LoginWrapper>
      <Route
        {...rest}
        render={(props) => <Component {...props} path={path} exact={exact} />}
      />
    </LoginWrapper>
  )
}

export default PublicRouter
