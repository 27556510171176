import styled, { css, keyframes } from 'styled-components'
import {
  AiOutlineFileAdd,
  BsPerson,
  AiOutlineEdit,
  AiOutlineLoading3Quarters,
  BiTrashAlt,
  AiOutlineEye
} from 'styles/icons'

export const Wrapper = styled.div``

export const MultipleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px 0;
  height: 250px;
`

export const UploadIcon = styled.img.attrs({
  src: '/icons/upload.svg'
})`
  /* max-width: 50px; */
`
export const MultipleTitle = styled.div`
  color: #b7b7b7;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
`

export const FileIcon = styled(AiOutlineFileAdd)`
  width: 35px;
  height: 35px;
  color: #1890ff;
  margin-top: -10px;
  margin-right: -20px;
`
export const TrashIcon = styled(BiTrashAlt)`
  width: 22px;
  height: 22px;
  margin-top: -2px;
  color: black;

  &:hover {
    color: #1890ff;
  }
`

export const PhotoWrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;

  .ant-modal-content {
    border-radius: 20px !important;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0 !important;
  }
`

export const RoundPhoto = styled.div<{ url?: string }>`
  width: 130px;
  height: 130px;
  background: #a5a5a5;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  ${({ url }) =>
    url &&
    css`
      background-image: url(${url});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    `}
`

const iconCSS = css`
  width: 22px;
  height: 22px;
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
`

export const EyeIcon = styled(AiOutlineEye)`
  ${iconCSS}
`

export const TrashAvatarIcon = styled(BiTrashAlt)`
  ${iconCSS}
`

export const IconsAvatar = styled.div`
  width: 130px;
  height: 130px;
  background: rgba(0, 0, 0, 0.5);
  gap: 0 10px;
  position: absolute;
  z-index: 30;
  top: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  opacity: 0;
  transition: opacity 0.4s;

  &:hover {
    opacity: 1;
  }
`

export const UserIcon = styled(BsPerson)<{ url: boolean }>`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  color: white;

  display: ${({ url }) => (url ? 'none' : 'block')};
`
export const EditWrapper = styled.button`
  outline: none;
  border: none;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 0;
  background: #1890ff;
  /* transition: 0.5s border; */
  cursor: pointer;
  z-index: 100;
`

export const PenIcon = styled(AiOutlineEdit)`
  color: white;
  width: 20px;
  height: 20px;
`

const load = keyframes`
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg);
  }
`

export const LoadIcon = styled(AiOutlineLoading3Quarters)`
  color: white;
  width: 20px;
  height: 20px;
  animation: ${load} linear infinite 0.7s;
`

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 450px;
  object-fit: contain;
  border-radius: 50%;
  flex-shrink: 0;
`

export const Submit = styled.button<{ isLoading?: boolean }>`
  padding: 5px 20px;
  border: 1px solid transparent;
  outline: none;
  background: #1890ff;
  border-radius: 4px;
  color: white;
  font-size: 12.5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.6s;
  position: relative;

  ${({ isLoading }) =>
    isLoading &&
    css`
      padding-right: 50px;
    `}

  &:hover {
    background: rgba(115, 158, 164, 1);
  }

  &:disabled {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &.save-and-stay {
    background: transparent;
    border: 1px solid #1890ff;
    color: red;

    &:hover {
      background: rgba(124, 175, 176, 0.1);
      color: green;
    }

    &:disabled {
      border: 1px solid rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.4);
      cursor: not-allowed;

      &:hover {
        background: transparent;
      }
    }
  }
`

export const Cancel = styled.button`
  color: #1890ff;
  font-size: 12.5px;
  outline: none;
  padding: 5px 20px;
  border-radius: 4px;
  border: none;
  background: transparent;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    background: rgba(124, 175, 176, 0.1);
    color: red;
  }
  &:disabled {
    background: transparent;
    color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;

    &:hover {
      background: transparent;
    }
  }
`
