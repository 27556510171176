import styled, { css } from 'styled-components'
import {
  QrcodeOutlined,
  CalendarOutlined,
  BookOutlined,
  AudioOutlined,
  PlayCircleOutlined,
  RocketOutlined,
  EditOutlined
} from '@ant-design/icons'
import { BiEraser, FaRunning, MdAlignHorizontalLeft } from 'styles/icons'

export const iconsCss = css`
  font-size: 18px;
  margin-right: 10px;
  flex-shrink: none;
`

export const HomeIcon = styled(MdAlignHorizontalLeft)`
  ${iconsCss}
`
export const ThemeIcon = styled(QrcodeOutlined)`
  ${iconsCss}
`
export const StudentIcon = styled(CalendarOutlined)`
  ${iconsCss}
`
export const VideoIcon = styled(PlayCircleOutlined)`
  ${iconsCss}
`

export const BookIcon = styled(BookOutlined)`
  ${iconsCss}
`

export const PodcastIcon = styled(AudioOutlined)`
  ${iconsCss}
`

export const CorrectionsIcons = styled(BiEraser)`
  ${iconsCss}
`

export const SuggestionsIcon = styled(RocketOutlined)`
  ${iconsCss}
`
export const MakeYourComposition = styled(EditOutlined)`
  ${iconsCss}
`

export const MarathonIcon = styled(FaRunning)`
  ${iconsCss}
`

export const Container = styled.aside<{ isOpen?: boolean }>`
  width: 270px;
  height: 100%;
  background-color: #001529;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 991px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${({ isOpen }) => (isOpen ? '0px' : '-270px')};
    z-index: 900;
    transition: 1s left;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.5);
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
  }
`
export const Image = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

export const Logo = styled.img.attrs({
  src: '/logo-l.png'
})`
  max-width: 80px;
`
export const Items = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 20px;
`

export const ListItems = styled.ul`
  list-style-type: none;

  a {
    color: white;
  }
`

export const Span = styled.span`
  display: block;
`

export const Item = styled.li`
  width: 100%;
  padding: 13px;
  padding-left: 0px;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 600;
  font-size: 13px;
  display: flex;

  &:hover {
    color: #1890ff;
  }
`
