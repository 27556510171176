const localhost = location.hostname === 'localhost'
const LOCAL: any = localhost ? 'DEVELOPMENT' : 'PROD'
// const LOCAL: any = 'PROD'

let env: ENVTYPES

type ENVTYPES = {
  CDN_FILES: string
  API: string
}

switch (LOCAL) {
  case 'PROD':
    env = {
      API: 'https://api.horadaredacao.com',
      CDN_FILES: 'https://cdn.horadaredacao.com'
    }
    break

  default:
    env = {
      API: 'http://localhost:3333',
      CDN_FILES: 'https://cdn.horadaredacao.com'
    }
    break
}

export default env
