import styled, { css } from 'styled-components'
import { BsChevronCompactLeft, BsChevronCompactRight } from 'styles/icons'
import ReactCarousel from 'react-elastic-carousel'

type CssType = {
  disabled?: boolean
  isVisible?: boolean
  isActive?: boolean
  paginationColor?: string
  arrowInside?: boolean
}

export const Container = styled.div<{ hasPagination?: boolean }>`
  position: relative;

  .rec-slider-container {
    margin: 0;
  }
`

export const Carousel = styled(ReactCarousel)`
  width: 100%;
`

const iconCSS = css`
  width: 45px;
  flex-shrink: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.hex};
  transition: color 0.5s;
  z-index: 10;
  height: 100%;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.hex};
  }
`
export const LeftArrow = styled(BsChevronCompactLeft)<CssType>`
  ${iconCSS}
  left: 10px;

  ${({ arrowInside }) =>
    arrowInside &&
    css`
      position: absolute;
    `}

  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.primary.hex};
      cursor: not-allowed;
      &:hover {
        color: ${({ theme }) => theme.colors.secondary.hex};
      }

      @media (max-width: 991px) {
        opacity: 0;
      }
    `}
`
export const RightArrow = styled(BsChevronCompactRight)<CssType>`
  ${iconCSS}
  right: 10px;

  ${({ arrowInside }) =>
    arrowInside &&
    css`
      position: absolute;
    `}

  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.primary.hex};
      cursor: not-allowed;

      &:hover {
        color: ${({ theme }) => theme.colors.secondary.hex};
      }

      @media (max-width: 991px) {
        opacity: 0;
      }
    `}
`

export const Pagination = styled.div<CssType>`
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 20px;
  padding-left: 150px;
  padding-right: 150px;
  justify-content: center;
  align-items: center;
  gap: 0 5px;

  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}

  @media (max-width: 991px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`

export const ItemPagination = styled.div<CssType>`
  width: 20px;
  background: ${({ paginationColor }) => paginationColor};
  height: ${({ isActive }) => (isActive ? '3px' : '2px')};
  border-radius: 10px;

  @media (max-width: 500px) {
    display: none;
  }
`
