import * as S from './styles'

export type SkeletonTypes = {
  height?: number
  width?: number | string
  rounded?: boolean
  bg?: string
  bgGhost?: string
  style?: React.CSSProperties
}

function Skeleton({
  height = 200,
  width = '100%',
  rounded = false,
  bg = '#eee',
  bgGhost = '#fff',
  style
}: SkeletonTypes) {
  return (
    <S.Container
      width={typeof width === 'number' ? `${width}px` : width}
      bg={bg}
      height={height}
      rounded={rounded}
      style={style}
    >
      <S.Ghost bgGhost={bgGhost} />
    </S.Container>
  )
}

export default Skeleton
