import styled from 'styled-components'
import { RiMedal2Fill } from 'styles/icons'

export const Container = styled.div`
  color: white;

  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;

  font-size: 15px;
`
export const ProfileName = styled.span`
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
`

export const MedalWrapper = styled.span`
  position: relative;
`

export const Medal = styled(RiMedal2Fill)<{ typeMedal: string }>`
  width: 23px;
  height: 23px;
  margin-top: 5px;
  position: absolute;
  top: -6px;
  color: ${({ theme, typeMedal }) => theme.colors.medal[typeMedal]};
`
