import styled from 'styled-components'

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  gap: 15px 0;
  flex-direction: column;
  padding: 10px;
`

export const Loading = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 0 10px;
  align-items: flex-end;
`
