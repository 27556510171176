import styled from 'styled-components'
import { Table, Modal as AntdModal, Popconfirm } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

export const Container = styled.div`
  width: 100%;
  padding: 20px;
`

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
`

export const Title = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.bold};
  font-size: 23px;
  font-weight: 600;
`

export const Description = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.clear};
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 991px) {
    font-size: 16px;
  }
`

export const Input = styled.div`
  max-width: 300px;
`

export const SearchArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 0 10px;

  @media (max-width: 991px) {
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px 0;
  }
`

export const Files = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;

  a {
    @media (max-width: 1218px) {
      flex: 1;
    }
  }
`

export const File = styled.div`
  width: 300px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow.card};
  background: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1218px) {
    flex: 1;
    width: 100%;
  }
`

export const Image = styled.img`
  max-width: 320px;
  object-fit: contain;
  max-height: 160px;

  @media (max-width: 363px) {
    max-width: 200px;
  }
`

export const FileTitle = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.normal};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  @media (max-width: 991px) {
    justify-content: center;
  }
`

export const Buttons = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  gap: 0 5px;
`

export const Actions = styled.div`
  display: flex;
  gap: 0 5px;
`

export const Anchor = styled.a.attrs({
  target: '_blank'
})``

export const List = styled(Table)`
  margin-top: 20px;
`
export const { Column } = Table
export const Modal = styled(AntdModal)``
export const CheckIcon = styled(CheckOutlined)`
  font-size: 20px;
  color: green;
`
export const Confirm = styled(Popconfirm)``

export const WrapperModal = styled.div`
  width: 100%;
`
