import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 20px;
`

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
`

export const Title = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.bold};
  font-size: 23px;
  font-weight: 600;
`

export const Description = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.clear};
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 991px) {
    font-size: 16px;
  }
`

export const Input = styled.div`
  max-width: 300px;
`

export const SearchArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 991px) {
    margin-top: 20px;
    justify-content: center;
  }
`

export const Files = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 1304px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 733px) {
    grid-template-columns: 1fr;
  }
`

export const File = styled.div`
  min-width: 257px;
  height: 100%;
  flex: 1;

  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow.card};
  background: white;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`

export const Image = styled.div<{ src: string }>`
  width: 320px;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 160px;

  @media (max-width: 400px) {
    width: 200px;
  }
`

export const FileTitle = styled.div`
  width: 300px;
  color: ${({ theme }) => theme.colors.text.normal};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  @media (max-width: 991px) {
    justify-content: center;
  }
`
