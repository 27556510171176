import * as S from './styles'
import { Menu, Dropdown } from 'antd'
import { useAuth } from 'contexts/login'
import { useState } from 'react'
import HeaderLoader from 'components/atoms/HeaderLoader'
import { Link, useHistory } from 'react-router-dom'
import {
  AreaChartOutlined,
  // BellOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons'
import { useProgress } from 'contexts/progress'

function Header() {
  const history = useHistory()

  const { handleChangeSideBar, sidebarIsOpen } = useProgress()
  const { signOut } = useAuth()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user] = useState(() => {
    // const data = JSON.parse(localStorage.getItem('HORADAREDACAO_USER') || '')

    return {}
  })

  const menu = (
    <Menu>
      <Link to="/configuracoes">
        <Menu.Item>Configurações</Menu.Item>
      </Link>
      <Menu.Item onClick={signOut}>Sair</Menu.Item>
    </Menu>
  )

  return (
    <>
      <S.WrapperContent isOpen={sidebarIsOpen} onClick={handleChangeSideBar} />
      <S.Container>
        <S.Left>
          <S.Mobile>
            {sidebarIsOpen ? (
              <MenuUnfoldOutlined
                size={43}
                style={{ fontSize: 23 }}
                onClick={handleChangeSideBar}
              />
            ) : (
              <MenuFoldOutlined
                style={{ fontSize: 23 }}
                onClick={handleChangeSideBar}
              />
            )}
          </S.Mobile>
          <S.Arrow onClick={() => history.goBack()} />
        </S.Left>
        <S.Right>
          <Link to="/dashboard">
            <S.Button shape="circle" title="dashboard" type="text">
              <AreaChartOutlined />
            </S.Button>
          </Link>
          {/* <S.Button shape="circle" title="notificações" type="text">
            <BellOutlined />
          </S.Button> */}
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <S.Button shape="circle" type="primary">
              <S.UserIcons />
            </S.Button>
          </Dropdown>
        </S.Right>
        <HeaderLoader />
      </S.Container>
    </>
  )
}

export default Header
