export type ParamsType = {
  page: string
}

export type AllowedPagesTypes = {
  name: string
  path: string
  description: string
  selector?: string
}

export const allowedPages: AllowedPagesTypes[] = [
  {
    name: 'Esquema em Vídeo',
    path: 'esquemas-em-video',
    selector: 'Esquema em Vídeo',
    description:
      'Nossos esquemas em vídeo são uma forma de ampliar sua percepção sobre os temas abordados'
  },
  {
    name: 'Aulas ENEM 2021',
    path: 'aulas-enem-2021',
    selector: 'Enem 2021',
    description: 'Aulas exclusivas para você assistir sempre que precisar'
  },
  {
    name: 'Aulas ENEM',
    path: 'aulas-enem',
    selector: 'Enem',
    description: 'Muito mais conteúdo para você assistir sempre que precisar'
  },
  {
    name: 'Aulas UERJ',
    path: 'aulas-uerj',
    selector: 'uerj',
    description: 'Aulas exclusivas para o vestibular da UERJ'
  },
  {
    name: 'Aulas Concurso',
    path: 'aulas-concurso',
    selector: 'Concurso',
    description:
      'Aulas específicas para você se preparar para os principais concursos do país'
  },
  {
    name: 'Aulas Escolas Militares',
    path: 'aulas-escolas-militares',
    selector: 'Escolas Militares',
    description: 'Prepare-se para as escolas militares com aulas exclusivas'
  },
  {
    name: 'Quadro',
    selector: 'quadros',
    description:
      'Copiar uma redação ajuda no desenvolvimento de linhas neurais e no aperfeiçoamento da sua redação',
    path: 'quadros'
  }
]
