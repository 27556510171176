import { useUsers } from 'contexts/user'
import { useEffect } from 'react'
import * as S from './styles'

function ProfileSidebar() {
  const { getInitialProfile, user, loading } = useUsers()

  useEffect(() => {
    getInitialProfile && getInitialProfile()
  }, [getInitialProfile])

  const greetingMessage = () => {
    const hour = new Date().getHours()
    if (hour < 12) return 'Bom dia'
    if (hour < 18) return 'Boa tarde'
    return 'Boa noite'
  }

  const getName = () => {
    const user = localStorage.getItem('HORADAREDACAO_USER')

    if (user) {
      const props: any = JSON.parse(user)

      // eslint-disable-next-line react/prop-types
      return props.name
    }

    return ''
  }

  const translateMedal = (value?: string) => {
    switch (value) {
      case 'Ouro':
        return 'gold'

      case 'Prata':
        return 'silver'
      default:
        return 'bronze'
    }
  }

  return (
    <S.Container>
      <span>{greetingMessage()},</span>
      <S.ProfileName title={getName()}>{getName()}</S.ProfileName>
      {!loading && (
        <S.MedalWrapper>
          Nível: {user?.access_type}
          <S.Medal typeMedal={translateMedal(user?.access_type) || 'bronze'} />
        </S.MedalWrapper>
      )}
    </S.Container>
  )
}

export default ProfileSidebar
