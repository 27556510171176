import { Spin } from 'antd'
import { useVideos } from 'contexts/videos'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import * as S from './styles'

const Viewer = () => {
  const { loadPage = () => true, video, pageLoading } = useVideos()

  const { id } = useParams<any>()

  useEffect(() => {
    loadPage(id)
  }, [id, loadPage])

  if (pageLoading) {
    return (
      <S.LoadingWrapper>
        <Spin size="large" />
      </S.LoadingWrapper>
    )
  }

  if (video.name === undefined) {
    return (
      <S.LoadingWrapper>
        <span>Não encontrado</span>
      </S.LoadingWrapper>
    )
  }

  return (
    <S.Container>
      <S.Title>{video.name}</S.Title>
      <S.Iframe
        src={`https://www.youtube.com/embed/${video.video_id}`}
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></S.Iframe>
    </S.Container>
  )
}

export default Viewer
