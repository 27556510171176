import { Spin } from 'antd'
import { useEffect } from 'react'
import * as S from './styles'
import AudioPlayer from 'components/atoms/AudioPlayer'
import { useMarathon } from 'contexts/marathon'
import { useParams } from 'react-router-dom'
import { isiOS } from 'utils/formatter'

const Listner = () => {
  const { loadPage = () => true, marathon, pageLoading } = useMarathon()

  const { id } = useParams<any>()

  useEffect(() => {
    loadPage(id)
  }, [id, loadPage])

  if (pageLoading) {
    return (
      <S.LoadingWrapper>
        <Spin size="large" />
      </S.LoadingWrapper>
    )
  }

  if (marathon.name === undefined) {
    return (
      <S.LoadingWrapper>
        <h2>Não encontrado</h2>
      </S.LoadingWrapper>
    )
  }

  return (
    <S.Container>
      <S.AudioWrapper>
        <S.Title>{marathon.name}</S.Title>
        {marathon.audio_published && (
          <>
            {isiOS() ? (
              <S.Audio controls>
                <source src={marathon.audio} type="audio/mpeg" />
                Your browser does not support the audio tag.
              </S.Audio>
            ) : (
              <AudioPlayer url={marathon.audio} />
            )}
          </>
        )}
      </S.AudioWrapper>

      <S.Wrapper>
        <S.Images>
          <S.Protector />
          {marathon.pdf_images.map((img: any) => (
            <S.Image loading="lazy" key={img} src={img} />
          ))}
        </S.Images>
      </S.Wrapper>
    </S.Container>
  )
}

export default Listner
